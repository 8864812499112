import { BaseType } from './baseType';
import { AssetType, OnlyAssetType, PalletType } from './pallet';
import { PeopleType } from './people';
import { TransactionType } from './transaction';
// 플러그인 사용 설정
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
const SeoulTimeZone = 'Asia/Seoul';

export interface AdmissionType extends BaseType {
	id: number;
	date: string;
	status: string;
	assets: AssetType[];
}

export interface PostAdmissionType extends BaseType {
	date: string;
	status: string;
	assets: AssetType[];
}

export class AdmissionType {
	constructor(entity?: Partial<any>) {
		this.id = entity?.id || 0;
		this.date = entity?.date || dayjs().format('YYYY-MM-DDTHH:mm:ss');
		this.status = entity?.status || 'IN';
		this.assets =
			entity?.assets.map((asset: any) => {
				return new AssetType(asset);
			}) || [];
	}
	static toAdmissionEntity(entity: AdmissionType): any {
		return {
			id: entity.id || 0,
			status: entity.status,
			date: entity.date,
			assets: entity.assets?.map((data) => {
				return AssetType.toAssetEntity(data);
			}),
		};
	}
}

export class PostAdmissionType {
	constructor(entity?: Partial<any>) {
		this.date = entity?.date || dayjs().subtract(9, 'h').format('YYYY-MM-DDTHH:mm:ss');
		this.status = entity?.status || 'IN';
		this.assets =
			entity?.assets.map((asset: any) => {
				return new AssetType(asset);
			}) || [];
	}
	static toAdmissionEntity(entity: PostAdmissionType): any {
		return {
			status: entity.status,
			date: entity.date,
			assets: entity.assets?.map((data) => {
				return AssetType.toAssetEntity(data);
			}),
		};
	}
}

export interface HistoryDataType extends BaseType {
	id: number;
	date: string;
	status: string;
	pallet?: AssetType[];
	transaction?: TransactionType[];
	people?: PeopleType;
	assets?: AssetType[];
}
