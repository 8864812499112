import { baseActions } from '@/reducers/baseReducer';
import { sh_1_key, sh_2_key } from '@/sagas/axiosConfig';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export function hashID(id: string): string {
	return CryptoJS.SHA256(id).toString(CryptoJS.enc.Hex);
}

const sh_1 = hashID(sh_1_key ? sh_1_key : '');
const sh_2 = hashID(sh_2_key ? sh_2_key : '');
// 사용 예

const Login = () => {
	const dispatch = useDispatch();
	const [userId, setUserId] = useState<string>('');
	const [userPw, setUserPw] = useState<string>('');

	return (
		<>
			<Grid container sx={{ height: '800px' }} alignItems={'center'} justifyContent={'center'}>
				<Grid
					item
					sx={{
						width: '500px',
						height: '460px',
						border: '5px solid #47805C',
						p: '60px',
						borderRadius: '25px',
					}}
				>
					<Grid container sx={{ pb: '65px' }}>
						<Grid item xs>
							<Typography align="center" variant="h1">
								(주)성화 SHPT
							</Typography>
						</Grid>
					</Grid>
					<Grid container alignItems={'center'} justifyContent={'center'}>
						<Grid item xs={3}>
							ID
						</Grid>
						<Grid item xs={9}>
							<TextField
								size="small"
								onChange={(e) => {
									setUserId(e.target.value);
								}}
							></TextField>
						</Grid>
					</Grid>
					<Grid container alignItems={'center'} justifyContent={'center'} sx={{ pt: '20px' }}>
						<Grid item xs={3}>
							Password
						</Grid>
						<Grid item xs={9}>
							<TextField
								size="small"
								type="password"
								onChange={(e) => {
									setUserPw(e.target.value);
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										const inputID = hashID(userId);
										const inputPW = hashID(userPw);
										if (inputID === sh_1 && inputPW === sh_2) {
											Cookies.set('verify_1', inputID, { expires: 8 / 24 });
											Cookies.set('verify_2', inputPW, { expires: 8 / 24 });
											dispatch(baseActions.setVerified(true));
										}
									}
								}}
							></TextField>
						</Grid>
					</Grid>
					<Grid container alignItems={'center'} justifyContent={'center'}>
						<Grid item>
							<Button
								variant="contained"
								sx={{ mt: '70px', width: '220px', backgroundColor: '#47805C' }}
								onClick={() => {
									const inputID = hashID(userId);
									const inputPW = hashID(userPw);
									if (inputID === sh_1 && inputPW === sh_2) {
										Cookies.set('verify_1', inputID, { expires: 8 / 24 });
										Cookies.set('verify_2', inputPW, { expires: 8 / 24 });
										dispatch(baseActions.setVerified(true));
									}
								}}
							>
								로그인
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default Login;
