import { green, grey } from '@mui/material/colors';
import merge from 'deepmerge';

const customBlue = {
	50: '#e9f0fb',
	100: '#c8daf4',
	200: '#a3c1ed',
	300: '#7ea8e5',
	400: '#6395e0',
	500: '#4782da',
	600: '#407ad6',
	700: '#485769',
	800: '#2f65cb',
	900: '#2052c2 ',
};
const customText = '#485769';

const defaultVariant = {
	name: 'DEFAULT',
	root: {
		color: customText,
	},
	palette: {
		mode: 'light',
		primary: {
			main: customText,
			contrastText: '#FFF',
		},
		secondary: {
			main: customText,
			contrastText: '#FFF',
		},
		background: {
			default: '#FFF',
			paper: '#FFF',
		},
		hover: {
			main: '#D5D5D5',
			contrastText: '#FFF',
		},
	},
	header: {
		color: grey[200],
		background: customBlue[800],
		search: {
			color: grey[100],
		},
		indicator: {
			background: green[700],
		},
	},
	footer: {
		color: grey[500],
		background: '#FFF',
	},
	sidebar: {
		color: '#8bb4dd',
		activeColor: '#206ebd',
		titleColor: '#206EBD',
		background: '#FFF',
		activeBackground: '#DFE9F4',
		header: {
			color: '#FFF',
			background: customBlue[800],
			brand: {
				color: '#FFFFFF',
			},
		},
		footer: {
			color: grey[800],
			background: '#F7F7F7',
			online: {
				background: green[500],
			},
		},
		badge: {
			color: '#FFF',
			background: customBlue[500],
		},
	},
};

const darkVariant = merge(defaultVariant, {
	name: 'DARK',
	palette: {
		mode: 'dark',
		primary: {
			main: customBlue[600],
			contrastText: '#FFF',
		},
		background: {
			default: '#1B2635',
			paper: '#233044',
		},
		text: {
			primary: 'rgba(255, 255, 255, 0.95)',
			secondary: 'rgba(255, 255, 255, 0.5)',
		},
	},
	header: {
		color: grey[300],
		background: '#233044',
		search: {
			color: grey[200],
		},
	},
	sidebar: {
		color: '#FFF',
		background: '#233044',
		header: {
			color: '#FFF',
			background: customBlue[800],
			brand: {
				color: '#FFFFFF',
			},
		},
		footer: {
			color: '#FFF',
			background: '#1B2635',
			online: {
				background: green[500],
			},
		},
		badge: {
			color: '#FFF',
			background: customBlue[500],
		},
	},
	footer: {
		color: grey[300],
		background: '#233044',
	},
});

const variants: Array<VariantType> = [defaultVariant, darkVariant];

export default variants;

export type VariantType = {
	name: string;
	palette: {
		primary: MainContrastTextType;
		secondary: MainContrastTextType;
	};
	header: ColorBgType & {
		search: {
			color: string;
		};
		indicator: {
			background: string;
		};
	};
	footer: ColorBgType;
	sidebar: ColorBgType & {
		header: ColorBgType & {
			brand: {
				color: string;
			};
		};
		footer: ColorBgType & {
			online: {
				background: string;
			};
		};
		badge: ColorBgType;
	};
};

type MainContrastTextType = {
	main: string;
	contrastText: string;
};
type ColorBgType = {
	color: string;
	background: string;
};
