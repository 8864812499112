import { TypographyOptions } from '@mui/material/styles/createTypography';

const customText = '#485769';
const typography: TypographyOptions = {
	fontFamily: ['Noto Sans KR'].join(','),
	fontSize: 20,
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightMedium: 500,
	fontWeightBold: 700,
	h1: {
		fontSize: '2.1rem',
		fontWeight: 600,
		lineHeight: 1.25,
		fontFamily: 'Noto Sans KR',
		color: customText,
	},
	h2: {
		fontSize: '1.75rem',
		fontWeight: 600,
		lineHeight: 1.25,
		fontFamily: 'Noto Sans KR',
		color: customText,
	},
	h3: {
		fontSize: '24px',
		fontWeight: 600,
		lineHeight: 1.25,
		fontFamily: 'Noto Sans KR',
		color: customText,
	},
	h4: {
		fontSize: '24px',
		fontWeight: 600,
		lineHeight: 1,
		fontFamily: 'Noto Sans KR',
		color: customText,
	},
	h5: {
		fontSize: '20px',
		fontWeight: 600,
		lineHeight: 1,
		fontFamily: 'Noto Sans KR',
		color: customText,
	},
	h6: {
		fontSize: '1rem',
		fontWeight: 500,
		lineHeight: 1.25,
		fontFamily: 'Noto Sans KR',
		color: customText,
	},
	body1: {
		fontSize: 20,
		fontFamily: 'Noto Sans KR',
		color: '#000',
		lineHeight: 1,
	},
	body2: {
		fontSize: 20,
		fontFamily: 'Noto Sans KR',
		color: customText,
		lineHeight: 1,
	},
	caption: {
		fontSize: 12,
		fontFamily: 'Noto Sans KR',
		color: customText,
		lineHeight: 1,
	},
	button: {
		fontSize: 16,
		fontWeight: 600,
		textTransform: 'none',
		fontFamily: 'Noto Sans KR',
	},
};

export default typography;
