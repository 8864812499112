import { SimpleTable, SimpleTableColumnType } from '@/components/Table';
import { RootState } from '@/store';
import { AddCircleOutline, EditOutlined, LocalPrintshopOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { PeopleType, PostPeopleType } from '@/types/people';
import { sendPalletPrintZpl, sendPeoplePrintZpl } from '@/components/ZebraZPLPrint';
import { assetActions } from '@/reducers/assetReducer';
import { AssetType, PostAssetType } from '@/types/pallet';
import { InitialEnum } from '@/types/baseType';
import Cookies from 'js-cookie';
import { baseActions } from '@/reducers/baseReducer';
import { ConfirmBox } from '@/components/ConfirmBox';

const tableColums: SimpleTableColumnType[] = [
	{ label: '', key: 'check', width: '40px', align: 'left' },
	{ label: 'ID', key: 'id', width: '10%', align: 'left', sort: (row: AssetType) => row.id },
	{
		label: '이름',
		key: 'name',
		width: '15%',
		align: 'left',
		sort: (row: AssetType) => (row.vehicles ? row.vehicles[0]?.name : ''),
	},
	{ label: '전화번호', key: 'tel', width: '25%', align: 'left' },
	{
		label: '차량번호',
		key: 'car_number',
		width: '15%',
		align: 'left',
		sort: (row: AssetType) => (row.vehicles ? row.vehicles[0]?.car_number : ''),
	},
	{ label: '회사명', key: 'company', width: '35%', align: 'left' },
];

type PeopleDialogProps = {
	dialogOpen: boolean;
	setDialogOpen: React.Dispatch<React.SetStateAction<(EventTarget & HTMLButtonElement) | boolean>>;
	isModify: boolean;
	clickedRow: AssetType | null;
	setClickedRow: React.Dispatch<
		React.SetStateAction<(EventTarget & HTMLButtonElement) | AssetType | null>
	>;
};

const PeopleCreateDialog = (props: PeopleDialogProps) => {
	const dispatch = useDispatch();
	const { dialogOpen, setDialogOpen, isModify, clickedRow, setClickedRow } = props;
	const [peopleDataList, setPeopleDataList] = useState<PeopleType[]>([new PeopleType()]);
	const [rowsPage, setRowsPage] = useState<number>(20);
	const [confirmAnchorEl, setConfirmAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(
		null,
	);
	const [required, setRequired] = useState<boolean>(false);

	const { created_asset } = useSelector((state: RootState) => state.asset);

	useEffect(() => {
		if (clickedRow) {
			let temp = [...peopleDataList];
			if (clickedRow.vehicles) {
				temp[0] = { ...clickedRow.vehicles[0] };
				setPeopleDataList([...temp]);
			}
		}
	}, [clickedRow]);

	useEffect(() => {
		if (created_asset) {
			dispatch(assetActions.getPeopleList({ page: 0, size: rowsPage }));
			setClickedRow(null);
		}
	}, [created_asset]);

	return (
		<Box>
			<Dialog
				open={dialogOpen}
				aria-labelledby="form-dialog-title"
				onClose={(e, reason) => {
					if (reason === 'escapeKeyDown') {
						setDialogOpen(false);
					}
				}}
				fullWidth={true}
				maxWidth="md"
			>
				<DialogContent>
					<Box sx={{ p: '10px' }}>
						<ConfirmBox
							element={confirmAnchorEl}
							setElement={setConfirmAnchorEl}
							onOk={() => {
								if (clickedRow) {
									dispatch(assetActions.deleteAsset(clickedRow?.id));
									setConfirmAnchorEl(null);
									setDialogOpen(false);
								}
							}}
							onCancel={() => {
								setConfirmAnchorEl(null);
							}}
							message="선택 항목을 삭제하시겠습니까?"
						/>
						<Grid container>
							<Grid item>
								<Typography variant="h1">{'차량 ' + (isModify ? '수정' : '등록')}</Typography>
							</Grid>
						</Grid>
						<Grid container alignItems={'center'} sx={{ mt: '25px' }} spacing={2}>
							<Grid item xs={0.6}>
								<Typography variant="body1"></Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography variant="body1">이름</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography variant="body1">전화번호</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography variant="body1">
									{'차량번호'}
									<span style={{ color: 'red' }}>*</span>
								</Typography>
							</Grid>
							<Grid item xs={3.4}>
								<Typography variant="body1">회사명</Typography>
							</Grid>
						</Grid>
						{peopleDataList.map((tr, index) => {
							return (
								<Grid container alignItems={'center'} sx={{ mt: '25px' }}>
									<Grid item xs={0.6}>
										<Typography variant="body1">{index + 1}</Typography>
									</Grid>
									<Grid item xs={2}>
										<TextField
											sx={{ width: '100%' }}
											onChange={(e) => {
												peopleDataList[index].name = e.target.value;
											}}
											defaultValue={isModify ? (clickedRow?.vehicles ? clickedRow?.vehicles[0].name : '') : ''}
										></TextField>
									</Grid>
									<Grid item xs={3}>
										<TextField
											sx={{ width: '100%' }}
											onChange={(e) => {
												peopleDataList[index].tel = e.target.value;
											}}
											defaultValue={isModify ? (clickedRow?.vehicles ? clickedRow?.vehicles[0].tel : '') : ''}
										></TextField>
									</Grid>
									<Grid item xs={3}>
										<TextField
											sx={{ width: '100%' }}
											onChange={(e) => {
												peopleDataList[index].car_number = e.target.value;
												if (e.target.value === '') {
													setRequired(true);
												} else {
													setRequired(false);
												}
											}}
											error={peopleDataList[index].car_number === ''}
											helperText={required ? '필수입력값입니다' : ''}
											defaultValue={
												isModify ? (clickedRow?.vehicles ? clickedRow?.vehicles[0].car_number : '') : ''
											}
										></TextField>
									</Grid>
									<Grid item xs={3.4}>
										<TextField
											sx={{ width: '100%' }}
											onChange={(e) => {
												peopleDataList[index].company = e.target.value;
											}}
											defaultValue={
												isModify ? (clickedRow?.vehicles ? clickedRow?.vehicles[0].company : '') : ''
											}
										></TextField>
									</Grid>
								</Grid>
							);
						})}
					</Box>
				</DialogContent>
				<DialogActions>
					{clickedRow ? (
						<Button
							color="error"
							onClick={(e) => {
								setConfirmAnchorEl(e.currentTarget);
							}}
						>
							삭제
						</Button>
					) : null}
					<Button
						onClick={(e) => {
							setDialogOpen(false);
							if (isModify) {
								if (clickedRow) {
									dispatch(
										assetActions.postPallet({
											...clickedRow,
											vehicles: [
												{
													...peopleDataList[0],
												},
											],
										} as AssetType),
									);
								}
							} else {
								peopleDataList.forEach((data) => {
									dispatch(
										assetActions.postPallet({
											name: data.car_number,
											status: 'IN',
											type: '차량',
											initial: InitialEnum.vehicle,
											note: '',
											vehicles: [
												{
													car_number: data.car_number,
													name: data.name,
													tel: data.tel,
													company: data.company,
												},
											],
										} as PostAssetType),
									);
								});
							}
						}}
					>
						저장
					</Button>
					<Button
						onClick={(e) => {
							setDialogOpen(false);
							setClickedRow(null);
							setPeopleDataList([new PeopleType()]);
						}}
					>
						취소
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

const PeopleBoard = () => {
	const dispatch = useDispatch();
	const [peopleList, setPeopleList] = useState<AssetType[]>([]);
	const [checkedPeoples, setCheckedPeoples] = useState<AssetType[]>([]);
	const [clickedRow, setClickedRow] = useState<AssetType | null>(null);
	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [rowsPage, setRowsPage] = useState<number>(20);
	const { data_list, countContents, delete_success } = useSelector(
		(state: RootState) => state.asset,
	);

	useEffect(() => {
		const cookie_page_str = Cookies.get('page');
		if (cookie_page_str) {
			const cookie_page = Number(cookie_page_str);
			if (cookie_page === page) {
				dispatch(assetActions.getPeopleList({ page: page, size: rowsPage }));
			}
		}
	}, [page, rowsPage]);

	useEffect(() => {
		setPeopleList([
			...data_list.map((entity: AssetType) => {
				if (entity.vehicles) {
					return { ...entity, vehicles: [new PeopleType(entity.vehicles[0])] };
				} else {
					return entity;
				}
			}),
		]);
	}, [data_list]);

	useEffect(() => {
		if (createModalOpen == false) {
			setClickedRow(null);
		}
	}, [createModalOpen]);

	useEffect(() => {
		if (delete_success) {
			dispatch(assetActions.getPeopleList({ page: page, size: rowsPage }));
		}
	}, [delete_success]);
	const checkPeople = (row: AssetType) => {
		if (checkedPeoples.filter((p) => p.id == row.id).length > 0) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<Box>
			<PeopleCreateDialog
				dialogOpen={createModalOpen}
				setDialogOpen={setCreateModalOpen}
				isModify={clickedRow != null}
				clickedRow={clickedRow}
				setClickedRow={setClickedRow}
			/>
			<Grid container justifyContent={'space-between'}>
				<Grid item xs></Grid>
				<Grid item sx={{ mr: '30px' }}>
					<Button
						onClick={() => {
							setCreateModalOpen(true);
						}}
					>
						<AddCircleOutline /> 등록
					</Button>
				</Grid>
				<Grid item>
					<Button
						onClick={() => {
							sendPeoplePrintZpl(checkedPeoples, 1);
							dispatch(baseActions.setAlert({ message: '프린트 전송 완료', type: 'success' }));
						}}
					>
						<LocalPrintshopOutlined /> 출력
					</Button>
				</Grid>
			</Grid>
			<Grid container sx={{ mt: '30px', border: '1px solid #3c3c3c' }}>
				<Grid item xs>
					<SimpleTable
						columns={[...tableColums] as SimpleTableColumnType[]}
						datasource={peopleList}
						pageRows={rowsPage}
						totalRows={countContents}
						getPage={setPage}
						rowsPage={[20, 40, 80]}
						setRowsPage={setRowsPage}
						onRowClick={(row: AssetType, e: any) => {
							if (e?.target.type !== 'checkbox') {
								setClickedRow(row);
								setCreateModalOpen(true);
							}
						}}
						renderCell={{
							check: (row: AssetType) => {
								return (
									<Checkbox
										checked={checkPeople(row)}
										onChange={(e) => {
											if (e.target.checked) {
												let temp = [...checkedPeoples];
												temp.push(row);
												setCheckedPeoples(temp);
											} else {
												let temp = checkedPeoples.filter((pallet) => pallet.id !== row.id);
												setCheckedPeoples(temp);
											}
											e.stopPropagation();
										}}
										size="small"
									/>
								);
							},
							id: (row: AssetType) => {
								if (row.vehicles) {
									return row.id;
								} else {
									return '-';
								}
							},
							name: (row: AssetType) => {
								if (row.vehicles) {
									return row.vehicles[0]?.name;
								} else {
									return '-';
								}
							},
							car_number: (row: AssetType) => {
								if (row.vehicles) {
									return row.vehicles[0]?.car_number;
								} else {
									return '-';
								}
							},
							tel: (row: AssetType) => {
								if (row.vehicles) {
									return row.vehicles[0]?.tel;
								} else {
									return '-';
								}
							},
							company: (row: AssetType) => {
								if (row.vehicles) {
									return row.vehicles[0]?.company;
								} else {
									return '-';
								}
							},
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PeopleBoard;
