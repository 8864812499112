import { AxiosRequestConfig, AxiosInstance } from 'axios';

import axios from 'axios';
// export const baseUrl = 'http://qocorp.iptime.org:50001';
export const baseUrl = process.env.REACT_APP_BACKEND_URL; //'http://shwindow.iptime.org:8080';
export const sh_1_key = process.env.REACT_APP_SH_KEY_1;
export const sh_2_key = process.env.REACT_APP_SH_KEY_2;
console.log('base URL', baseUrl);
const axiosRequestConfiguration: AxiosRequestConfig = {
	baseURL: baseUrl,
	responseType: 'json',
	timeout: 20000,
	headers: {
		'Content-Type': 'application/json',
		Accept: '*/*',
	},
};

const initialization = (config: AxiosRequestConfig): AxiosInstance => {
	const axiosInstance = axios.create(config);

	return axiosInstance;
};

export const useAxios = initialization(axiosRequestConfiguration);
