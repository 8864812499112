import { ListRequestType, ResponseType } from '@/types/baseType';
import { AdmissionType, PostAdmissionType } from '@/types/history';
import { AssetType, PalletType, PostAssetType, PostPalletType } from '@/types/pallet';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface admissionState {
	data_list: AdmissionType[];
	created_history: AdmissionType;
	countContents: number;
	excel_data_list: AdmissionType[];
}

const initialState = {
	data_list: [] as AdmissionType[],
	created_history: new AdmissionType(),
	countContents: 0,
	excel_data_list: [] as AdmissionType[],
};

const admissionReducers = {
	initState: (state: admissionState) => {
		state.data_list = [];
		state.created_history = new AdmissionType();
		state.countContents = 0;
		state.excel_data_list = [] as AdmissionType[];
	},
	getHistoryList: (
		state: admissionState,
		action: PayloadAction<ListRequestType<{ startDate: string; endDate: string }>>,
	) => {},
	getHistoryListSuccess: (
		state: admissionState,
		action: PayloadAction<ResponseType<AdmissionType>>,
	) => {
		state.data_list = action.payload.content.map((content) => {
			return new AdmissionType(content);
		});
		state.countContents = action.payload.totalElements;
	},
	getHistoryListExcel: (
		state: admissionState,
		action: PayloadAction<ListRequestType<{ startDate: string; endDate: string }>>,
	) => {},
	getHistoryListExcelSuccess: (
		state: admissionState,
		action: PayloadAction<ResponseType<AdmissionType>>,
	) => {
		state.excel_data_list = action.payload.content.map((content) => {
			return new AdmissionType(content);
		});
	},
	postHistory: (state: admissionState, action: PayloadAction<AdmissionType>) => {},
	postHistorySuccess: (state: admissionState, action: PayloadAction<AdmissionType>) => {
		state.created_history = action.payload;
	},
};
export const admissionSlice = createSlice({
	name: 'admission',
	initialState,
	reducers: admissionReducers,
});

export const admissionActions = admissionSlice.actions!;

export default admissionSlice.reducer;
