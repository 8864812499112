import { BaseType } from './baseType';
import { AdmissionType } from './history';
import { PeopleType, PostPeopleType } from './people';
import { PostTransactionType, TransactionType } from './transaction';

export interface PalletType extends BaseType, PostPalletType {
	id: number;
}

export interface PostPalletType extends BaseType {
	name: string;
}

export interface AssetType extends BaseType, PostAssetType {
	id: string;
	transactions?: TransactionType[];
	vehicles?: PeopleType[];
	pallets?: PalletType[];
}

export class AssetType {
	constructor(entity?: Partial<any>) {
		this.id = entity?.id || '';
		this.name = entity?.name || '';
		this.status = entity?.status || '';
		this.type = entity?.type || '';
		this.initial = entity?.initial || '';
		this.note = entity?.note || '';
		this.transactions = entity?.shippingOrders
			? entity?.shippingOrders[0]
				? [new TransactionType(entity?.shippingOrders[0])]
				: undefined
			: undefined;
		this.vehicles = entity?.vehicles
			? entity?.vehicles[0]
				? [new PeopleType(entity?.vehicles[0])]
				: undefined
			: undefined;
		this.pallets = entity?.dieFrames
			? entity?.dieFrames[0]
				? [new PalletType(entity?.dieFrames[0])]
				: undefined
			: undefined;
	}
	static toAssetEntity(entity: AssetType): any {
		return {
			id: entity.id,
			name: entity.name,
			status: entity.status,
			type: entity.type,
			initial: entity.initial,
			note: entity.note,
			dieFrames: entity.pallets,
			shippingOrders: entity.transactions?.map((data) => {
				return TransactionType.toShippingOrderEntity(data);
			}),
			vehicles: entity.vehicles?.map((data) => {
				return PeopleType.toVehicleEntity(data);
			}),
		};
	}
}

export interface OnlyAssetType extends BaseType, PostAssetType {
	id: string;
}

export class OnlyAssetType {
	constructor(entity?: Partial<any>) {
		this.id = entity?.id || 0;
		this.name = entity?.name || '';
		this.status = entity?.status || '';
		this.type = entity?.type || '';
		this.initial = entity?.initial || '';
		this.note = entity?.note || '';
	}
}

export interface PostAssetType extends BaseType {
	name: string;
	status: string;
	type: string;
	initial: string;
	note: string;
	transactions?: PostTransactionType[];
	vehicles?: PeopleType[];
	pallets?: PostPalletType[];
}

export class PalletType {
	constructor(entity?: Partial<any>) {
		this.id = entity?.id || '';
		this.name = entity?.name || '';
	}
}

export interface PalletAdmissionType {
	asset: AssetType;
	latestAdmission: AdmissionType;
}

export class PalletAdmissionType {
	constructor(entity?: Partial<any>) {
		this.asset = new AssetType(entity?.asset) || '';
		this.latestAdmission = new AdmissionType(entity?.latestAdmission) || '';
	}
}
