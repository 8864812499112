import { Alert, Box, Button, Grid, Snackbar, Tab, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import HistoryBoard from './historyBoard';
import PalletBoard from './palletBoard';
import TransactionBoard from './trasactionBoard';
import PeopleBoard from './peopleBoard';
import Cookies from 'js-cookie';
import { RootState } from '@/store';
import Login, { hashID } from './login';
// import QOLogo from '../../public/QO_CI_black.png';
import { baseActions, baseState } from '@/reducers/baseReducer';
import axios, { AxiosResponse } from 'axios';
import { baseUrl, sh_1_key, sh_2_key, useAxios } from '@/sagas/axiosConfig';

interface Message {
	isPowerOn: boolean;
}

const Home = () => {
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState<string>('1');
	const [alertOpen, setAlertOpen] = useState<boolean>(false);
	const [socket, setSocket] = useState<WebSocket | null>(null);
	const [readerState, setReaderState] = useState<boolean>(false);

	const { alert, isVerified } = useSelector((state: RootState) => state.base);

	// WebSocket 연결 설정
	useEffect(() => {
		const url = baseUrl?.split('//')[1];
		console.log('env', process.env.NODE_ENV);
		const ws = new WebSocket(
			(process.env.NODE_ENV === 'development' ? 'ws://' : 'wss://') + url + '/ws',
		);

		ws.onopen = () => {
			console.log('Connected to WebSocket');
			setSocket(ws);
		};

		ws.onmessage = (event) => {
			console.log('onmessage :', event.data);
			const data: Message = JSON.parse(event.data);
			setReaderState(data.isPowerOn);
		};

		ws.onclose = () => {
			console.log('WebSocket connection closed');
		};

		ws.onerror = (error) => {
			console.error('WebSocket error:', error);
		};

		return () => {
			ws.close();
		};
	}, []);

	useEffect(() => {
		const tab = Cookies.get('tab');
		if (tab) {
			setTabIndex(tab);
		}
		setInterval(() => {
			const verify_1 = Cookies.get('verify_1');
			const verify_2 = Cookies.get('verify_2');
			const sh_1 = hashID(sh_1_key ? sh_1_key : '');
			const sh_2 = hashID(sh_2_key ? sh_2_key : '');
			if (verify_1) {
				if (verify_1 === sh_1 && verify_2 === sh_2) {
					dispatch(baseActions.setVerified(true));
				} else {
					dispatch(baseActions.setVerified(false));
				}
			} else {
				dispatch(baseActions.setVerified(false));
			}
		}, 60000);
	}, []);

	useEffect(() => {
		if (alert.message) {
			setAlertOpen(alert.message == '' ? false : true);
		}
	}, [alert]);

	useEffect(() => {
		const verify_1 = Cookies.get('verify_1');
		const verify_2 = Cookies.get('verify_2');
		const sh_1 = hashID(sh_1_key ? sh_1_key : '');
		const sh_2 = hashID(sh_2_key ? sh_2_key : '');
		if (verify_1) {
			if (verify_1 === sh_1 && verify_2 === sh_2) {
				dispatch(baseActions.setVerified(true));
			} else {
				dispatch(baseActions.setVerified(false));
			}
		}
	}, [isVerified]);

	// useAxios.interceptors.response.use(
	// 	function getStatusCode(params: AxiosResponse<any>) {
	// 		console.log('success', params);
	// 		return params;
	// 	},
	// 	function whenFail(error: any) {
	// 		const { config, response } = error;
	// 		console.log('fail', response);
	// 		return Promise.reject(error);
	// 	},
	// );

	return (
		<>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={alertOpen}
				autoHideDuration={3000}
				onClose={() => {
					setAlertOpen(false);
					// dispatch(baseActions.setAlert({ message: '', type: 'info' }));
				}}
			>
				<Alert
					onClose={() => {
						setAlertOpen(false);
					}}
					severity={alert.type}
					variant="filled"
					sx={{ width: '100%' }}
				>
					{alert.message}
				</Alert>
			</Snackbar>
			<Box
				sx={{
					p: '20px',
					border: '1px solid #3c3c3c',
					width: '90%',
					minWidth: '1500px',
					minHeight: '800px',
				}}
			>
				{isVerified ? (
					<TabContext value={tabIndex}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Grid container alignItems={'center'}>
								<Grid item xs>
									<TabList
										onChange={(e, newValue) => {
											setTabIndex(newValue);
											Cookies.set('tab', newValue, { expires: 1 });
											Cookies.set('page', '0');
										}}
										aria-label="lab API tabs example"
									>
										<Tab sx={{ fontSize: '24px' }} label="팔레트 히스토리" value={'1'} />
										<Tab sx={{ fontSize: '24px' }} label="팔레트 현황" value={'2'} />
										<Tab sx={{ fontSize: '24px' }} label="출고증 관리" value={'3'} />
										<Tab sx={{ fontSize: '24px' }} label="차량 관리" value={'4'} />
									</TabList>
								</Grid>
								<Grid item xs></Grid>
								{/* <Grid item>
								<Box
									component="img"
									sx={{
										height: 40,
										ml: 2,
									}}
									alt="QO Logo"
									src={QOLogo}
								/>
							</Grid> */}
								<Grid item>{'Reader Status : '}</Grid>
								<Grid item sx={{ ml: '5px' }}>
									<Typography variant="h5" color={readerState ? 'green' : 'red'}>
										{readerState ? ' 정상' : ' 미작동'}
									</Typography>
								</Grid>
								<Grid item>
									<Typography sx={{ fontSize: '28px', fontWeight: 600, ml: '20px', mb: '10px', mr: '20px' }}>
										(주)성화
									</Typography>
								</Grid>
							</Grid>
						</Box>
						<TabPanel value="1">
							<HistoryBoard />
						</TabPanel>
						<TabPanel value="2">
							<PalletBoard />
						</TabPanel>
						<TabPanel value="3">
							<TransactionBoard />
						</TabPanel>
						<TabPanel value="4">
							<PeopleBoard />
						</TabPanel>
					</TabContext>
				) : (
					<Login />
				)}
			</Box>
			<Box sx={{ width: '90%', minWidth: '1520px', mt: '10px' }}>
				<Typography width={'100%'} align="right" color={'#D1D1D1'} fontSize={'14px'}>
					Copyright by QO Corp . 1.0.17
				</Typography>
			</Box>
		</>
	);
};

export default Home;
