import { Menu, TextField } from '@mui/material';
import { DateCalendar, MonthCalendar } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import ko from 'dayjs/locale/ko';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.locale(ko);
const SeoulTimeZone = 'Asia/Seoul';
import { useState, useEffect } from 'react';

type DatePickerProps = {
	onChageDate: (date: Dayjs) => void;
	type?: 'd' | 'm';
	value?: Date;
};
const SimpleDatePicker = (props: DatePickerProps) => {
	const { type = 'd' } = props;
	const [selectedDate, setDate] = useState<Dayjs>();
	const [AnchorEl, setAnchorEl] = useState<null | (EventTarget & HTMLDivElement)>(null);

	useEffect(() => {
		if (selectedDate) {
			props.onChageDate(selectedDate);
		}
	}, [selectedDate]);

	return (
		<>
			<Menu
				id="simple-menu"
				anchorEl={AnchorEl}
				keepMounted
				open={Boolean(AnchorEl)}
				onClose={() => {
					setAnchorEl(null);
				}}
			>
				{type === 'd' ? (
					<DateCalendar
						onChange={(e: Dayjs) => {
							setDate(e.endOf('day'));
							setAnchorEl(null);
						}}
					/>
				) : (
					<MonthCalendar
						onChange={(e: Dayjs) => {
							setDate(e.endOf('m'));
							setAnchorEl(null);
						}}
					/>
				)}
			</Menu>
			<TextField
				placeholder="시작날짜"
				size="small"
				sx={{ backgroundColor: 'white', borderRadius: '8px', width: '140px' }}
				value={
					props.value ? dayjs(props.value).format('YYYY-MM-DD') : selectedDate?.format('YYYY-MM-DD')
				}
				InputProps={{ sx: { height: '30px' } }}
				onClick={(e) => {
					setAnchorEl(e.currentTarget);
				}}
			></TextField>
		</>
	);
};

export default SimpleDatePicker;
