import { SimpleTable, SimpleTableColumnType } from '@/components/Table';
import { RootState } from '@/store';
import { AssetType, OnlyAssetType, PalletType } from '@/types/pallet';
import { AddCircleOutline, EditOutlined, FileDownloadOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
const SeoulTimeZone = 'Asia/Seoul';

import { AdmissionType, HistoryDataType, PostAdmissionType } from '@/types/history';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import SimpleDatePicker from '@/components/DatePicker';
import { excelExport } from '@/components/excelExport';
import { admissionActions } from '@/reducers/historyReducer';
import { BaseType, InitialEnum } from '@/types/baseType';
import { assetActions } from '@/reducers/assetReducer';
import { TransactionType } from '@/types/transaction';
import { PeopleType } from '@/types/people';
import Cookies from 'js-cookie';

const tableColums: SimpleTableColumnType[] = [
	{
		label: '시간',
		key: 'time',
		width: '12.98%',
		align: 'left',
		sort: (row: HistoryDataType) => row.date,
	},
	{
		label: '상태',
		key: 'status',
		width: '6.02%',
		align: 'left',
	},
	{
		label: '팔레트',
		key: 'pallet',
		width: '7.59%',
		align: 'left',
	},
	{
		label: '거래처',
		key: 'company',
		width: '9.98%',
		align: 'left',
		sort: (row: HistoryDataType) => (row.transaction ? row.transaction[0].company : null),
	},
	{
		label: '현장주소',
		key: 'site',
		width: '18.92%',
		align: 'left',
		sort: (row: HistoryDataType) => (row.transaction ? row.transaction[0].site : null),
	},
	{ label: '연락처', key: 'receiverTel', width: '7.69%', align: 'left' },
	{ label: '비고', key: 'note', width: '22.54%', align: 'left' },
	{ label: '차량', key: 'delivery', width: '7.59%', align: 'left' },
	{ label: '연락처', key: 'deliveryTel', width: '7.69%', align: 'left' },
];

const PalletColums: {
	[key: string]: SimpleTableColumnType[];
} = {
	pallet: [
		{ label: '', key: 'check', width: '40px', align: 'left' },
		{ label: 'ID', key: 'id', width: '20.81%', align: 'left', sort: (row: AssetType) => row.id },
		{
			label: '상태',
			key: 'status',
			width: '20.02%',
			align: 'left',
			sort: (row: AssetType) => row.status,
		},
		{
			label: '순번',
			key: 'name',
			width: '29.20%',
			align: 'left',
			sort: (row: AssetType) => row.name,
		},
		{
			label: '타입',
			key: 'type',
			width: '29.20%',
			align: 'left',
			sort: (row: AssetType) => row.type,
		},
	],
	transaction: [
		{ label: '', key: 'check', width: '40px', align: 'left' },
		{ label: 'ID', key: 'id', width: '14.81%', align: 'left', sort: (row: AssetType) => row.id },
		{
			label: '거래처',
			key: 'company',
			width: '14.07%',
			align: 'left',
			sort: (row: AssetType) => (row.transactions ? row.transactions[0].company : ''),
		},
		{ label: '현장주소', key: 'site', width: '20.70%', align: 'left' },
		{ label: '연락처', key: 'receiverTel', width: '13.33%', align: 'left' },
		{ label: '비고', key: 'note', width: '11.11%', align: 'left' },
	],
	vehicle: [
		{ label: '', key: 'check', width: '40px', align: 'left' },
		{ label: 'ID', key: 'id', width: '10%', align: 'left', sort: (row: AssetType) => row.id },
		{
			label: '이름',
			key: 'name',
			width: '15%',
			align: 'left',
			sort: (row: AssetType) => (row.vehicles ? row.vehicles[0]?.name : ''),
		},
		{ label: '전화번호', key: 'tel', width: '25%', align: 'left' },
		{
			label: '차량',
			key: 'car_number',
			width: '15%',
			align: 'left',
			sort: (row: AssetType) => (row.vehicles ? row.vehicles[0]?.car_number : ''),
		},
		{ label: '회사명', key: 'company', width: '35%', align: 'left' },
	],
};

const renderCells: {
	[key: string]: {
		[key: string]: (row: BaseType) => any;
	};
} = {
	pallet: {
		status: (row: AssetType) => {
			const isIn = row.status == 'IN';
			return (
				<Typography sx={{ color: isIn ? '#062DDC' : '#DB1E09', fontWeight: '600' }}>
					{isIn ? '입고' : '출고'}
				</Typography>
			);
		},
		assetType: (row: AssetType) => {
			return <Typography>{row.type}</Typography>;
		},
	},
	transaction: {
		company: (row: AssetType) => {
			if (row.transactions) {
				return <Typography>{row.transactions[0]?.company}</Typography>;
			} else {
				return '-';
			}
		},
		site: (row: AssetType) => {
			if (row.transactions) {
				return <Typography>{row.transactions[0]?.site}</Typography>;
			} else {
				return '-';
			}
		},
		receiver: (row: AssetType) => {
			if (row.transactions) {
				return row.transactions[0]?.receiverName;
			} else {
				return '-';
			}
		},
		receiverTel: (row: AssetType) => {
			if (row.transactions) {
				return row.transactions[0]?.receiverTel;
			} else {
				return '-';
			}
		},
		bf: (row: AssetType) => {
			if (row.transactions) {
				return row.transactions[0]?.bf;
			} else {
				return '-';
			}
		},
		sf: (row: AssetType) => {
			if (row.transactions) {
				return row.transactions[0]?.sf;
			} else {
				return '-';
			}
		},
		mf: (row: AssetType) => {
			if (row.transactions) {
				return row.transactions[0]?.mf;
			} else {
				return '-';
			}
		},
		gl: (row: AssetType) => {
			if (row.transactions) {
				return row.transactions[0]?.glass;
			} else {
				return '-';
			}
		},
		note: (row: AssetType) => {
			if (row.transactions) {
				return row.transactions[0]?.note;
			} else {
				return '-';
			}
		},
	},
	vehicle: {
		id: (row: AssetType) => {
			if (row.vehicles) {
				return row.id;
			} else {
				return '-';
			}
		},
		name: (row: AssetType) => {
			if (row.vehicles) {
				return row.vehicles[0]?.name;
			} else {
				return '-';
			}
		},
		car_number: (row: AssetType) => {
			if (row.vehicles) {
				return row.vehicles[0]?.car_number;
			} else {
				return '-';
			}
		},
		tel: (row: AssetType) => {
			if (row.vehicles) {
				return row.vehicles[0]?.tel;
			} else {
				return '-';
			}
		},
		company: (row: AssetType) => {
			if (row.vehicles) {
				return row.vehicles[0]?.company;
			} else {
				return '-';
			}
		},
	},
};

type SelectDialogProps = {
	dialogOpen: boolean;
	setDialogOpen: React.Dispatch<React.SetStateAction<(EventTarget & HTMLButtonElement) | boolean>>;
	clickedRow?: AssetType | null;
	setClickedRow?: React.Dispatch<
		React.SetStateAction<(EventTarget & HTMLButtonElement) | AssetType | null>
	>;
	dataType: string;
	checkedList: AssetType[];
	setCheckedList: React.Dispatch<
		React.SetStateAction<(EventTarget & HTMLButtonElement) | AssetType[] | null>
	>;
};

const PalletSelectDialog = (props: SelectDialogProps) => {
	const dispatch = useDispatch();
	const {
		dialogOpen,
		setDialogOpen,
		clickedRow,
		setClickedRow,
		dataType,
		checkedList,
		setCheckedList,
	} = props;
	const [datalist, setDataList] = useState<AssetType[]>([]);
	const [checkedTransactions, setCheckedTransactions] = useState<AssetType[]>([]);
	const [page, setPage] = useState<number>(0);
	const [rowsPage, setRowsPage] = useState<number>(20);
	const { created_assets, data_list, countContents } = useSelector(
		(state: RootState) => state.asset,
	);

	useEffect(() => {
		setCheckedTransactions([...checkedList]);
	}, [checkedList]);

	useEffect(() => {
		if (dataType === 'pallet') {
			dispatch(assetActions.getPalletList({ page: page, size: rowsPage }));
		} else if (dataType === 'transaction') {
			dispatch(assetActions.getTransactionList({ page: page, size: rowsPage }));
		} else if (dataType === 'vehicle') {
			dispatch(assetActions.getPeopleList({ page: page, size: rowsPage }));
		} else {
		}
	}, [dataType, page]);

	useEffect(() => {
		if (dataType === 'pallet') {
			setDataList([
				...data_list.map((entity: AssetType) => {
					if (entity.dieFrames) {
						return { ...entity, pallets: [new PalletType(entity.dieFrames[0])] };
					} else {
						return entity;
					}
				}),
			]);
		} else if (dataType === 'transaction') {
			setDataList([
				...data_list.map((entity: AssetType) => {
					if (entity.shippingOrders) {
						return { ...entity, transactions: [new TransactionType(entity.shippingOrders[0])] };
					} else {
						return entity;
					}
				}),
			]);
		} else if (dataType === 'vehicle') {
			setDataList([
				...data_list.map((entity: AssetType) => {
					if (entity.vehicles) {
						return { ...entity, vehicles: [new PeopleType(entity.vehicles[0])] };
					} else {
						return entity;
					}
				}),
			]);
		} else {
		}
	}, [data_list]);

	const checkTransaction = (row: AssetType) => {
		if (checkedTransactions.filter((p) => p.id == row.id).length > 0) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<Box>
			<Dialog
				open={dialogOpen}
				aria-labelledby="form-dialog-title"
				onClose={(e, reason) => {
					if (reason === 'escapeKeyDown') {
						setDialogOpen(false);
					}
				}}
				fullWidth={true}
				maxWidth={dataType === 'transaction' ? 'lg' : 'md'}
			>
				<DialogContent>
					<Box sx={{ p: '10px' }}>
						<Grid container>
							<Grid item>
								<Typography>팔레트 선택</Typography>
							</Grid>
						</Grid>
						<Grid container sx={{ mt: '30px', border: '1px solid #3c3c3c' }}>
							<Grid item xs>
								<SimpleTable
									columns={[...PalletColums[dataType]] as SimpleTableColumnType[]}
									datasource={datalist}
									pageRows={rowsPage}
									totalRows={countContents}
									getPage={setPage}
									rowsPage={[]}
									setRowsPage={setRowsPage}
									onRowClick={(row: AssetType, e: any) => {
										// if (e?.target.type !== 'checkbox') {
										// 	setClickedRow(row);
										// 	setDialogOpen(false);
										// }
									}}
									renderCell={{
										...renderCells[dataType],
										check: (row: AssetType) => {
											return (
												<Checkbox
													checked={checkTransaction(row)}
													onChange={(e) => {
														if (e.target.checked) {
															let temp = [...checkedTransactions];
															temp.push(row);
															setCheckedTransactions(temp);
														} else {
															let temp = checkedTransactions.filter((pallet) => pallet.id !== row.id);
															setCheckedTransactions(temp);
														}
														e.stopPropagation();
													}}
													size="small"
												/>
											);
										},
									}}
								/>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={(e) => {
							setDialogOpen(false);
							setCheckedList([...checkedTransactions]);
							setCheckedTransactions([]);
						}}
					>
						저장
					</Button>
					<Button
						onClick={(e) => {
							setDialogOpen(false);
						}}
					>
						취소
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

type HistoryDialogProps = {
	dialogOpen: boolean;
	setDialogOpen: React.Dispatch<React.SetStateAction<(EventTarget & HTMLButtonElement) | boolean>>;
	isModify: boolean;
	clickedRow: HistoryDataType | null;
	setClickedRow: React.Dispatch<
		React.SetStateAction<(EventTarget & HTMLButtonElement) | HistoryDataType | null>
	>;
};

const HistoryCreateDialog = (props: HistoryDialogProps) => {
	const dispatch = useDispatch();
	const { dialogOpen, setDialogOpen, isModify, clickedRow, setClickedRow } = props;
	const [historyObject, setHistoryObject] = useState<AdmissionType>(new AdmissionType());
	const { created_assets } = useSelector((state: RootState) => state.asset);

	const [palletDialog, setPalletDialog] = useState<boolean>(false);
	const [selectDatas, setSelectDatas] = useState<AssetType[]>([]);
	const [dataType, setDataType] = useState<string>('pallet');
	const [inOut, setInOut] = useState<string>('IN');

	const [selectedPallet, setSelectedPallet] = useState<AssetType[]>([]);
	const [selectedTransaction, setSelectedTransaction] = useState<TransactionType[]>([]);
	const [selectedVehicle, setSelectedVehicle] = useState<PeopleType>(new PeopleType());

	useEffect(() => {
		if (clickedRow) {
			let temp = { ...historyObject };
			temp.id = clickedRow.id;
			temp.date = dayjs(clickedRow.date).format('YYYY-MM-DDTHH:mm:ss');
			temp.status = clickedRow.status;
			if (clickedRow.pallet) {
				setSelectedPallet(clickedRow.pallet);
			}
			if (clickedRow.transaction) {
				setSelectedTransaction(clickedRow.transaction);
			}
			if (clickedRow.vehicle) {
				setSelectedVehicle(clickedRow.vehicle);
			}
			if (clickedRow.assets) {
				temp.assets = clickedRow.assets;
			}
			setHistoryObject({ ...temp });
		}
	}, [clickedRow]);

	useEffect(() => {
		if (created_assets.length > 0) {
			// dispatch(assetActions.getTransactionList());
			setClickedRow(null);
		}
	}, [created_assets]);

	useEffect(() => {
		if (selectDatas.length > 0) {
			let temp = { ...historyObject };
			if (selectDatas[0].initial == 'DI') {
				let assets = temp.assets.filter((asset) => asset.initial != 'DI');
				if (assets) {
					selectDatas.forEach((selectData) => {
						assets.push(selectData);
					});
					temp.assets = assets;
				}
				setSelectedPallet([...selectDatas]);
			}
			if (selectDatas[0].initial == 'OR') {
				let assets = temp.assets.filter((asset) => asset.initial != 'OR');
				if (assets) {
					let tempArray: TransactionType[] = [];
					selectDatas.forEach((selectData) => {
						if (selectData.transactions) {
							assets.push(selectData);
							tempArray.push({ ...selectData.transactions[0] });
							temp.assets = assets;
						}
					});
					setSelectedTransaction([...tempArray]);
				}
			}
			if (selectDatas[0].initial == 'VH') {
				let assets = temp.assets.filter((asset) => asset.initial != 'VH');
				if (assets) {
					if (selectDatas.length > 0) {
						if (selectDatas[0].vehicles) {
							assets.push(selectDatas[0]);
							setSelectedVehicle({ ...selectDatas[0].vehicles[0] });
							temp.assets = assets;
						}
					}
				}
			}
			setHistoryObject({ ...temp });
		}
	}, [selectDatas]);

	return (
		<Box>
			<PalletSelectDialog
				dialogOpen={palletDialog}
				setDialogOpen={setPalletDialog}
				checkedList={selectDatas}
				setCheckedList={setSelectDatas}
				dataType={dataType}
			/>
			<Dialog
				open={dialogOpen}
				aria-labelledby="form-dialog-title"
				onClose={(e, reason) => {
					if (reason === 'escapeKeyDown') {
						setDialogOpen(false);
					}
				}}
				fullWidth={true}
				maxWidth="lg"
			>
				<DialogContent>
					<Box sx={{ p: '10px' }}>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<Typography>시간</Typography>
							</Grid>
							<Grid item xs={1.5}>
								<Typography>입/출고</Typography>
							</Grid>
							<Grid item xs={1.8}>
								<Typography>팔레트</Typography>
							</Grid>
							<Grid item xs={2.8}>
								<Typography>출고증</Typography>
							</Grid>
							<Grid item xs={2.8}>
								<Typography>차량</Typography>
							</Grid>
						</Grid>
						<Grid container sx={{ mt: '10px' }} spacing={2}>
							<Grid item xs={3}>
								<TextField
									defaultValue={
										clickedRow
											? dayjs(clickedRow.date).format('YY-MM-DD HH:mm:ss')
											: dayjs().format('YY-MM-DD HH:mm:ss')
									}
									onChange={(e) => {
										if (e) {
											let temp = { ...historyObject };
											temp = {
												...temp,
												date: dayjs(e.target.value, 'YY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss'),
											} as AdmissionType;
											setHistoryObject({ ...temp });
										}
									}}
								/>
							</Grid>
							<Grid item xs={1.5}>
								<Select
									sx={{ minWidth: '100%' }}
									defaultValue={
										clickedRow
											? clickedRow.pallet
												? clickedRow.pallet[0].status
												: historyObject.status
											: '-'
									}
									onChange={(e: SelectChangeEvent) => {
										let temp = { ...historyObject };
										let assets = temp.assets.filter((asset) => asset.initial == 'DI');
										if (assets.length > 0) {
											assets[0].status = e.target.value;
											temp.assets = assets;
										}
										temp.status = e.target.value;
										setHistoryObject({ ...temp });
									}}
								>
									<MenuItem value={'IN'}>{'입고'}</MenuItem>
									<MenuItem value={'OUT'}>{'출고'}</MenuItem>
								</Select>
							</Grid>
							<Grid item xs={1.8}>
								<TextField
									onClick={(e) => {
										setDataType('pallet');
										setPalletDialog(true);
										e.preventDefault();
										if (clickedRow) {
											if (clickedRow.pallet) {
												setSelectDatas([...clickedRow.pallet]);
											}
										}
										setSelectDatas([...selectedPallet]);
									}}
									defaultValue={
										clickedRow
											? clickedRow.pallet
												? clickedRow.pallet.map((plt, i) => {
														let val = '';
														if (i == 0) val += plt.name + '/' + plt.type;
														else val += '\r\n' + plt.name + '/' + plt.type;
														return val;
													})
												: ''
											: ''
									}
									value={selectedPallet.map((plt, i) => {
										let val = '';
										if (i == 0) val += plt.name + '/' + plt.type;
										else val += '\r\n' + plt.name + '/' + plt.type;
										return val;
									})}
									multiline
									InputProps={{
										style: {
											whiteSpace: 'pre-line',
										},
									}}
								/>
							</Grid>
							<Grid item xs={2.8}>
								<TextField
									onClick={(e) => {
										setDataType('transaction');
										setPalletDialog(true);
										e.preventDefault();
										if (clickedRow) {
											if (clickedRow.transaction) {
												const list = historyObject.assets.filter((asset) => asset.initial == 'OR');
												setSelectDatas([...list]);
											}
										}
										setSelectDatas([...historyObject.assets.filter((asset) => asset.initial == 'OR')]);
									}}
									defaultValue={
										clickedRow
											? clickedRow.transaction
												? clickedRow.transaction.map((tr, i) => {
														let val = '';
														if (i == 0) val += tr.site;
														else val += '\r\n' + tr.site;
														return val;
													})
												: ''
											: ''
									}
									value={selectedTransaction.map((tr, i) => {
										let val = '';
										if (i == 0) val += tr.site;
										else val += '\r\n' + tr.site;
										return val;
									})}
									multiline
									InputProps={{
										style: {
											whiteSpace: 'pre-line',
										},
									}}
								/>
							</Grid>
							<Grid item xs={2.8}>
								<TextField
									onClick={(e) => {
										setDataType('vehicle');
										setPalletDialog(true);
										e.preventDefault();
										if (clickedRow) {
											if (clickedRow.vehicle) {
												const list = historyObject.assets.filter((asset) => asset.initial == 'VH');
												setSelectDatas([...list]);
											}
										}
										setSelectDatas([...historyObject.assets.filter((asset) => asset.initial == 'VH')]);
									}}
									defaultValue={clickedRow ? clickedRow.vehicle?.car_number : ''}
									value={selectedVehicle.car_number}
								/>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={(e) => {
							setDialogOpen(false);
							dispatch(admissionActions.postHistory({ ...historyObject }));
							setSelectedPallet([]);
							setSelectedTransaction([]);
							setSelectedVehicle(new PeopleType());
							setHistoryObject(new AdmissionType());
							setClickedRow(null);
						}}
					>
						저장
					</Button>
					<Button
						onClick={(e) => {
							setDialogOpen(false);
							setSelectedPallet([]);
							setSelectedTransaction([]);
							setSelectedVehicle(new PeopleType());
							setHistoryObject(new AdmissionType());
							setClickedRow(null);
						}}
					>
						취소
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

const HistoryBoard = () => {
	const dispatch = useDispatch();
	const [historyList, setHistoryList] = useState<HistoryDataType[]>([]);
	const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(7, 'days'));
	const [endDate, setEndDate] = useState<Dayjs>(dayjs());
	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [rowsPage, setRowsPage] = useState<number>(20);
	const [excelDownload, setExcelDownload] = useState<boolean>(false);
	const [sortColumn, setSortColumn] = useState<string>('');
	const [clickedRow, setClickedRow] = useState<HistoryDataType | null>(null);

	const { data_list, created_history, countContents, excel_data_list } = useSelector(
		(state: RootState) => state.history,
	);

	useEffect(() => {
		const cookie_page_str = Cookies.get('page');
		if (cookie_page_str) {
			const cookie_page = Number(cookie_page_str);
			if (cookie_page === page) {
				const cookieStartDate = Cookies.get('startDate');
				const cookieEndDate = Cookies.get('endDate');
				if (cookieStartDate && cookieEndDate) {
					dispatch(
						admissionActions.getHistoryList({
							page: page,
							size: rowsPage,
							contents: {
								startDate: cookieStartDate,
								endDate: cookieEndDate,
							},
						}),
					);
					setStartDate(dayjs(cookieStartDate));
					setEndDate(dayjs(cookieEndDate));
				} else {
					dispatch(
						admissionActions.getHistoryList({
							page: page,
							size: rowsPage,
							contents: {
								startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
								endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
							},
						}),
					);
				}
			}
		} else {
			dispatch(
				admissionActions.getHistoryList({
					page: page,
					size: rowsPage,
					contents: {
						startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
						endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
					},
				}),
			);
		}
	}, [page, rowsPage]);

	useEffect(() => {
		if (created_history.id !== 0) {
			dispatch(
				admissionActions.getHistoryList({
					page: 0,
					size: rowsPage,
					contents: {
						startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
						endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
					},
				}),
			);
		}
	}, [created_history]);

	useEffect(() => {
		let tempList: HistoryDataType[] = [];
		let temp = [...data_list];
		temp.forEach((data) => {
			let tempData: HistoryDataType = {} as HistoryDataType;
			tempData.id = data.id;
			tempData.date = data.date;
			tempData.status = data.status;
			if (data.assets.length > 0) {
				data.assets.forEach((asset: AssetType) => {
					Object.entries(InitialEnum).forEach((initial) => {
						if (asset.initial == initial[1]) {
							if (initial[1] == 'DI') {
								if (tempData.pallet) {
									tempData.pallet.push(asset);
								} else {
									tempData[initial[0]] = [asset];
								}
							} else if (initial[1] == 'OR') {
								if (tempData.transaction) {
									tempData.transaction.push(asset[initial[0] + 's'][0]);
								} else {
									tempData[initial[0]] = [asset[initial[0] + 's'][0]];
								}
							} else {
								tempData[initial[0]] = asset[initial[0] + 's'][0];
							}
						}
					});
					if (tempData.assets) {
						tempData.assets.push(asset);
					} else {
						tempData.assets = [asset];
					}
				});
			}
			tempList.push(tempData);
		});
		setHistoryList([...tempList]);
	}, [data_list]);

	useEffect(() => {
		if (excelDownload)
			if (excel_data_list.length > 0 && excel_data_list.length === countContents) {
				let tempList: HistoryDataType[] = [];
				let temp = [...excel_data_list];
				temp.forEach((data) => {
					let tempData: HistoryDataType = {} as HistoryDataType;
					tempData.id = data.id;
					tempData.date = data.date;
					if (data.assets.length > 0) {
						data.assets.forEach((asset: AssetType) => {
							Object.entries(InitialEnum).forEach((initial) => {
								if (asset.initial == initial[1]) {
									if (initial[1] == 'DI') {
										tempData[initial[0]] = new OnlyAssetType(asset);
									} else if (initial[1] == 'OR') {
										if (tempData.transaction) {
											tempData.transaction.push(asset[initial[0] + 's'][0]);
										} else {
											tempData[initial[0]] = [asset[initial[0] + 's'][0]];
										}
									} else {
										tempData[initial[0]] = asset[initial[0] + 's'][0];
									}
								}
							});
						});
					}
					tempList.push(tempData);
				});
				excelExport({
					headers: tableColums,
					datasource: tempList,
					fileName: 'history_' + startDate.format('YYMMDD') + '_' + endDate.format('YYMMDD'),
					renderCell: {
						time: (row: HistoryDataType) => {
							return dayjs(row.date).format('YY-MM-DD HH:mm:ss');
						},
						status: (row: HistoryDataType) => {
							const isIn = row.status == 'IN';
							return isIn ? '입고' : '출고';
						},
						pallet: (row: HistoryDataType) => {
							if (row.pallet) {
								row.pallet.map((plt) => {
									let palletName = plt.name;
									let palletType = plt.type;
									if (palletName === undefined) {
										palletName = '';
									}
									if (palletType === undefined) {
										palletType = '';
									}
									return (
										<Typography sx={{ whiteSpace: 'pre-line' }}>{palletName + ' / ' + palletType}</Typography>
									);
								});
							}
						},
						company: (row: HistoryDataType) => {
							return (
								<Box sx={{ whiteSpace: 'pre-line' }}>
									{row.transaction?.map((tr) => tr.company).join('\r\n')}
								</Box>
							);
						},
						site: (row: HistoryDataType) => {
							return (
								<Box sx={{ whiteSpace: 'pre-line' }}>
									{row.transaction?.map((tr) => tr.site).join('\r\n')}
								</Box>
							);
						},
						receiver: (row: HistoryDataType) => {
							return (
								<Box sx={{ whiteSpace: 'pre-line' }}>
									{row.transaction?.map((tr) => tr.receiverName).join('\r\n')}
								</Box>
							);
						},
						receiverTel: (row: HistoryDataType) => {
							return (
								<Box sx={{ whiteSpace: 'pre-line' }}>
									{row.transaction?.map((tr) => tr.receiverTel).join('\r\n')}
								</Box>
							);
						},
						note: (row: HistoryDataType) => {
							return (
								<Box sx={{ whiteSpace: 'pre-line' }}>
									{row.transaction?.map((tr) => tr.note).join('\r\n')}
								</Box>
							);
						},
						delivery: (row: HistoryDataType) => {
							return row.vehicle?.name;
						},
						deliveryTel: (row: HistoryDataType) => {
							return row.vehicle?.tel;
						},
					},
				});
				setExcelDownload(false);
			}
	}, [excel_data_list]);

	useEffect(() => {}, [sortColumn]);

	return (
		<Box>
			<HistoryCreateDialog
				dialogOpen={createModalOpen}
				setDialogOpen={setCreateModalOpen}
				isModify={clickedRow != null}
				clickedRow={clickedRow}
				setClickedRow={setClickedRow}
			/>
			<Grid container justifyContent={'space-between'} alignItems={'center'}>
				<Grid item sx={{ mr: '10px' }}>
					<SimpleDatePicker
						value={startDate.toDate()}
						onChageDate={(date) => {
							if (endDate.diff(date) > 0) {
								const newStartDate = date.startOf('day');
								setStartDate(newStartDate);
								Cookies.set('startDate', newStartDate.format('YYYY-MM-DDTHH:mm:ss'));
								Cookies.set('endDate', endDate.format('YYYY-MM-DDTHH:mm:ss'));
								dispatch(
									admissionActions.getHistoryList({
										page: page,
										size: rowsPage,
										contents: {
											startDate: newStartDate.format('YYYY-MM-DDTHH:mm:ss'),
											endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
										},
									}),
								);
							}
						}}
					/>
				</Grid>
				<Grid item sx={{ mr: '10px' }}>
					<Typography>~</Typography>
				</Grid>
				<Grid item sx={{ mr: '30px' }}>
					<SimpleDatePicker
						value={endDate.toDate()}
						onChageDate={(date) => {
							if (date.diff(startDate) > 0) {
								const newEndDate = date.endOf('day');
								setEndDate(newEndDate);
								Cookies.set('startDate', dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss'));
								Cookies.set('endDate', newEndDate.format('YYYY-MM-DDTHH:mm:ss'));
								dispatch(
									admissionActions.getHistoryList({
										page: page,
										size: rowsPage,
										contents: {
											startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
											endDate: newEndDate.format('YYYY-MM-DDTHH:mm:ss'),
										},
									}),
								);
							}
						}}
					/>
				</Grid>
				<Grid item xs>
					<Button
						size="small"
						onClick={(e) => {
							dispatch(
								admissionActions.getHistoryListExcel({
									page: 0,
									size: countContents,
									contents: {
										startDate: startDate.format('YYYY-MM-DDTHH:mm:ss'),
										endDate: endDate.format('YYYY-MM-DDTHH:mm:ss'),
									},
								}),
							);
							setExcelDownload(true);
						}}
					>
						<FileDownloadOutlined /> 엑셀 다운로드
					</Button>
				</Grid>
				<Grid item xs></Grid>
				<Grid item>
					<Button
						onClick={() => {
							setCreateModalOpen(true);
						}}
					>
						<AddCircleOutline /> 등록
					</Button>
				</Grid>
			</Grid>
			<Grid container sx={{ mt: '30px', border: '1px solid #3c3c3c' }}>
				<Grid item xs>
					<SimpleTable
						columns={[...tableColums]}
						datasource={historyList}
						pageRows={rowsPage}
						totalRows={countContents}
						getPage={setPage}
						rowsPage={[20, 40, 80]}
						setRowsPage={setRowsPage}
						onRowClick={(row: HistoryDataType) => {
							setClickedRow(row);
							setCreateModalOpen(true);
						}}
						setSort={(sort) => setSortColumn(sort)}
						renderCell={{
							time: (row: HistoryDataType) => {
								return <Typography>{dayjs(row.date).format('YY-MM-DD HH:mm:ss')}</Typography>;
							},
							status: (row: HistoryDataType) => {
								const isIn = row.status == 'IN';
								return (
									<Typography sx={{ color: isIn ? '#062DDC' : '#DB1E09', fontWeight: '600' }}>
										{isIn ? '입고' : '출고'}
									</Typography>
								);
							},
							pallet: (row: HistoryDataType) => {
								if (row.pallet) {
									let palletText = '';
									row.pallet.forEach((plt) => {
										let palletName = plt.name;
										let palletType = plt.type;
										if (palletName === undefined) {
											palletName = '';
										}
										if (palletType === undefined) {
											palletType = '';
										}
										palletText += palletName + ' / ' + palletType + '\r\n';
									});
									return <Typography sx={{ whiteSpace: 'pre-line' }}>{palletText}</Typography>;
								}
							},
							company: (row: HistoryDataType) => {
								if (row.transaction) {
									return (
										<Typography sx={{ whiteSpace: 'pre-line' }}>
											{row.transaction?.map((tr) => tr.company).join('\r\n')}
										</Typography>
									);
								}
							},
							site: (row: HistoryDataType) => {
								if (row.transaction) {
									return (
										<Typography sx={{ whiteSpace: 'pre-line' }}>
											{row.transaction?.map((tr) => tr.site).join('\r\n')}
										</Typography>
									);
								}
							},
							receiverTel: (row: HistoryDataType) => {
								if (row.transaction) {
									return (
										<Typography sx={{ whiteSpace: 'pre-line' }}>
											{row.transaction?.map((tr) => tr.receiverTel).join('\r\n')}
										</Typography>
									);
								}
							},
							note: (row: HistoryDataType) => {
								if (row.transaction) {
									return (
										<Typography sx={{ whiteSpace: 'pre-line' }}>
											{row.transaction?.map((tr) => tr.note).join('\r\n')}
										</Typography>
									);
								}
							},
							delivery: (row: HistoryDataType) => {
								return row.vehicle?.car_number;
							},
							deliveryTel: (row: HistoryDataType) => {
								return row.vehicle?.tel;
							},
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default HistoryBoard;
