import { PeopleType } from '@/types/people';
import { TransactionType } from '@/types/transaction';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';
import { Device } from 'zebra-browser-print-wrapper/lib/types';

function stringToHex(str: string): string {
	let hex: string = '';
	for (let i = 0; i < str.length; i++) {
		hex += str.charCodeAt(i).toString(16).padStart(2, '0');
	}
	return hex;
}

function hexToString(hex: string): string {
	let str: string = '';
	for (let i = 0; i < hex.length; i += 2) {
		const code: number = parseInt(hex.substr(i, 2), 16);
		str += String.fromCharCode(code);
	}
	return str;
}

export const sendPalletPrintZpl = async (list: any[], qty: number) => {
	//성화 프린트 192.168.0.230:9100
	const labelForm =
		'^XA^FS\r\n^DFR:PALLETLABEL.ZPL^FS\r\n^FO322,98^A0N,226,204^FH^CI28^FN1"No"^FS^CI28^PQ1,,,Y^XZ';
	try {
		// Create a new instance of the object
		const browserPrint = new ZebraBrowserPrintWrapper();
		console.log('browserPrint', browserPrint);
		try {
			const availablePrinters: Device[] = await browserPrint.getAvailablePrinters();
			console.log('available', availablePrinters);
			if (availablePrinters.length > 0) {
				// Select default printer
				const defaultPrinter: Device =
					availablePrinters.length > 0 ? availablePrinters[0] : ({} as Device);
				if (availablePrinters.length > 0) {
				}
				console.log('defaultPrinter', defaultPrinter);

				// Set the printer
				browserPrint.setPrinter(defaultPrinter);
				let requestCount = 0;
				const labelPrint = async (count: number) => {
					// Check printer status
					const printerStatus = await browserPrint.checkPrinterStatus();
					console.log('labelPrint status : ', printerStatus.isReadyToPrint);
					// Check if the printer is ready
					if (printerStatus.isReadyToPrint) {
						// ZPL script to print a simple barcode
						list.forEach((label, index) => {
							for (let i = 0; i < qty; i++) {
								if (label.id) {
									const test = stringToHex(label.id);
									const zpl = `^XA\r\n^BY2,2.0^FS\r\n^SEE:UHANGUL.DAT^FS\r\n^CW1,E:KFONT3.FNT^CI28^FS\r\n^XFR:PALLETLABEL.ZPL^FS\r\n^FN1^FD${
										label.name
									}^FS^RFW,H^FD${test}^FS\r\n^PQ1\r\n^XZ`;
									// const zpl = `^XA\r\n^BY2,2.0^FS\r\n^SEE:UHANGUL.DAT^FS\r\n^CW1,E:KFONT3.FNT^CI28^FS\r\n^XFR:LABELFORM.ZPL^FS\r\n^RFW,H^FD${label.uuid}^FS\r\n^PQ1\r\n^XZ`;
									setTimeout(
										function () {
											console.log('time', new Date().toISOString());
											console.log('zpl', labelForm + zpl);
											browserPrint.print(labelForm + zpl);
										},
										1000 * (index + 1),
									);
								}
							}
						});
					} else {
						console.log('Print Request Error/s', printerStatus);
						if (printerStatus.errors.indexOf('Unknown') != -1) {
							console.log('requestCount', requestCount);
							if (requestCount < 2) {
								requestCount += 1;
								await labelPrint(requestCount);
							}
						}
					}
				};

				await labelPrint(requestCount);
			}
		} catch (error) {
			console.log('Error/s', error);
		}
	} catch (error) {
		throw new Error(error);
	}
};

export const sendTransactionPrintZpl = async (list: any[], qty: number) => {
	//성화 프린트 192.168.0.230:9100
	const labelForm =
		'^XA^FS\r\n^DFR:TRANSACTIONFORM.ZPL^FS\r\n' +
		'~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD15^JUS^LRN^CI27^PA0,1,1,0^MMT' +
		'^PW719^LL240^LS0^FO40,90^GB719,270,6^FS^FO46,175^GFA,37,460,92,:Z64:eJz7/5824AcDAwONjB7SZtMSAAAUK2KQ:70C9^FO46,264^GFA,37,368,92,:Z64:eJz7/5824AcDAwONjB7SZgMApk9ikA==:22F8\r\n' +
		'^FO65,113^A1N,62,35^FH^CI28^FN1"id"^FS^CI28\r\n' +
		'^FO65,199^A1N,62,33^FH^CI28^FN2"orderingCompany"^FS^CI28\r\n' +
		'^FO65,275^A1N,51,25^FH^CI28^FN3"labelSite"^FS^CI28\r\n' +
		'^PQ1,,,Y\r\n' +
		'^XZ';
	try {
		// Create a new instance of the object
		const browserPrint = new ZebraBrowserPrintWrapper();
		console.log('browserPrint', browserPrint);
		try {
			const availablePrinters: Device[] = await browserPrint.getAvailablePrinters();
			console.log('available', availablePrinters);
			if (availablePrinters.length > 0) {
				// Select default printer
				const defaultPrinter: Device =
					availablePrinters.length > 0 ? availablePrinters[0] : ({} as Device);
				if (availablePrinters.length > 0) {
				}
				console.log('defaultPrinter', defaultPrinter);

				// Set the printer
				browserPrint.setPrinter(defaultPrinter);
				let requestCount = 0;
				const labelPrint = async (count: number) => {
					// Check printer status
					const printerStatus = await browserPrint.checkPrinterStatus();
					console.log('labelPrint status : ', printerStatus.isReadyToPrint);
					// Check if the printer is ready
					if (printerStatus.isReadyToPrint) {
						// ZPL script to print a simple barcode
						list.forEach((label, index) => {
							for (let i = 0; i < qty; i++) {
								if (label.id) {
									const test = stringToHex(label.id);
									let transaction = {} as TransactionType;
									if (label.transactions) {
										transaction = label.transactions[0];
									}
									const zpl = `^XA\r\n
									^BY2,2.0^FS\r\n
									^SEE:UHANGUL.DAT^FS\r\n
									^CW1,E:KFONT3.FNT^CI28^FS\r\n
									^XFR:TRANSACTIONFORM.ZPL^FS\r\n
									^FN1^FD${label.id}^FS\r\n
									^FN2^FD${transaction.company}^FS\r\n
									^FN3^FD${transaction.site}^FS\r\n
									^RFW,H^FD${test}^FS\r\n
									^PQ1^XZ`;
									// const zpl = `^XA\r\n^BY2,2.0^FS\r\n^SEE:UHANGUL.DAT^FS\r\n^CW1,E:KFONT3.FNT^CI28^FS\r\n^XFR:LABELFORM.ZPL^FS\r\n^RFW,H^FD${label.uuid}^FS\r\n^PQ1\r\n^XZ`;
									setTimeout(
										function () {
											console.log('time', new Date().toISOString());
											console.log('zpl', labelForm + zpl);
											browserPrint.print(labelForm + zpl);
										},
										1000 * (index + 1),
									);
								}
							}
						});
					} else {
						console.log('Print Request Error/s', printerStatus);
						if (printerStatus.errors.indexOf('Unknown') != -1) {
							console.log('requestCount', requestCount);
							if (requestCount < 2) {
								requestCount += 1;
								await labelPrint(requestCount);
							}
						}
					}
				};

				await labelPrint(requestCount);
			}
		} catch (error) {
			console.log('Error/s', error);
		}
	} catch (error) {
		throw new Error(error);
	}
};

export const sendPeoplePrintZpl = async (list: any[], qty: number) => {
	//성화 프린트 192.168.0.230:9100
	const labelForm = `^XA^FS
	^DFR:CARLABEL.ZPL^FS
	~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR6,6~SD15^JUS^LRN^CI27^PA0,1,1,0^MMT^PW780^LL250^LS0^FO40,90^GB710,250,6^FS
	^FO60,136^FB140,3,1,C,0^A1N,60,35^FH^CI28^FN1"company"^FS^CI28
	^FO255,136^A1N,190,70^FH^CI28^FN2"carNumber"^FS^CI28
	^PQ1,,,Y
	^XZ`;
	try {
		// Create a new instance of the object
		const browserPrint = new ZebraBrowserPrintWrapper();
		console.log('browserPrint', browserPrint);
		try {
			const availablePrinters: Device[] = await browserPrint.getAvailablePrinters();
			console.log('available', availablePrinters);
			if (availablePrinters.length > 0) {
				// Select default printer
				const defaultPrinter: Device =
					availablePrinters.length > 0 ? availablePrinters[0] : ({} as Device);
				if (availablePrinters.length > 0) {
				}
				console.log('defaultPrinter', defaultPrinter);

				// Set the printer
				browserPrint.setPrinter(defaultPrinter);
				let requestCount = 0;
				const labelPrint = async (count: number) => {
					// Check printer status
					const printerStatus = await browserPrint.checkPrinterStatus();
					console.log('labelPrint status : ', printerStatus.isReadyToPrint);
					// Check if the printer is ready
					if (printerStatus.isReadyToPrint) {
						// ZPL script to print a simple barcode
						list.forEach((label, index) => {
							for (let i = 0; i < qty; i++) {
								if (label.id) {
									const test = stringToHex(label.id);
									let vehicle = {} as PeopleType;
									if (label.vehicles) {
										vehicle = label.vehicles[0];
									}
									const zpl = `^XA\r\n^BY2,2.0^FS\r\n^SEE:UHANGUL.DAT^FS\r\n^CW1,E:KFONT3.FNT^CI28^FS\r\n^XFR:CARLABEL.ZPL^FS\r\n^FN1^FD${
										vehicle.company
									}^FS^FN2^FD${vehicle.car_number}^FS^RFW,H^FD${test}^FS\r\n^PQ1\r\n^XZ`;
									// const zpl = `^XA\r\n^BY2,2.0^FS\r\n^SEE:UHANGUL.DAT^FS\r\n^CW1,E:KFONT3.FNT^CI28^FS\r\n^XFR:LABELFORM.ZPL^FS\r\n^RFW,H^FD${label.uuid}^FS\r\n^PQ1\r\n^XZ`;
									setTimeout(
										function () {
											console.log('time', new Date().toISOString());
											console.log('zpl', labelForm + zpl);
											browserPrint.print(labelForm + zpl);
										},
										1000 * (index + 1),
									);
								}
							}
						});
					} else {
						console.log('Print Request Error/s', printerStatus);
						if (printerStatus.errors.indexOf('Unknown') != -1) {
							console.log('requestCount', requestCount);
							if (requestCount < 2) {
								requestCount += 1;
								await labelPrint(requestCount);
							}
						}
					}
				};

				await labelPrint(requestCount);
			}
		} catch (error) {
			console.log('Error/s', error);
		}
	} catch (error) {
		throw new Error(error);
	}
};
