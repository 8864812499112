import { useAxios } from './axiosConfig';
import { AssetType, PalletType } from '@/types/pallet';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { takeEvery, all, call, put } from 'redux-saga/effects';
import { admissionActions, admissionState } from '@/reducers/historyReducer';
import { AdmissionType, PostAdmissionType } from '@/types/history';
import { ListRequestType } from '@/types/baseType';
import dayjs from 'dayjs';
import { baseActions } from '@/reducers/baseReducer';

const getHistoryListRequest = (
	action: PayloadAction<ListRequestType<{ startDate: string; endDate: string }>>,
) => {
	//서버에 요청을 보내는 부분.
	if (action.payload.contents) {
		return useAxios.get(
			'admissions/history?startDate=' +
				action.payload.contents?.startDate +
				'&endDate=' +
				action.payload.contents?.endDate +
				'&page=' +
				action.payload.page +
				'&size=' +
				action.payload.size +
				'&sort=date,desc',
		);
	} else {
		return useAxios.get(
			'admissions/history?startDate=' +
				dayjs().subtract(7, 'days').toISOString() +
				'&endDate=' +
				dayjs().toISOString() +
				'&page=' +
				action.payload.page +
				'&size=' +
				action.payload.size +
				'&sort=date,desc',
		);
	}
};

function* getHistoryList(action: PayloadAction<admissionState>) {
	try {
		const response: AxiosResponse<any> = yield call(getHistoryListRequest, action as any);
		yield put(admissionActions.getHistoryListSuccess(response.data));
	} catch (err) {
		console.dir(err);
	}
}

function* getHistoryListExcel(action: PayloadAction<admissionState>) {
	try {
		const response: AxiosResponse<any> = yield call(getHistoryListRequest, action as any);
		yield put(admissionActions.getHistoryListExcelSuccess(response.data));
	} catch (err) {
		console.dir(err);
	}
}

const postHistoryRequest = (action: PayloadAction<AdmissionType>) => {
	//서버에 요청을 보내는 부분.
	const entity = AdmissionType.toAdmissionEntity(action.payload);
	return useAxios.post('admissions', entity);
};

function* postHistory(action: PayloadAction<admissionState>) {
	try {
		const response: AxiosResponse<any> = yield call(postHistoryRequest, action as any);
		yield put(admissionActions.postHistorySuccess(response.data));
		yield put(baseActions.setAlert({ message: '생성 완료', type: 'success' }));
	} catch (err) {
		console.dir(err);
	}
}

export function* admissionSaga() {
	yield takeEvery(admissionActions.getHistoryList.type, getHistoryList);
	yield takeEvery(admissionActions.getHistoryListExcel.type, getHistoryListExcel);
	yield takeEvery(admissionActions.postHistory.type, postHistory);
}
