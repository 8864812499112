import * as excelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { SimpleTableColumnType } from './Table';
import { BaseType } from '@/types/baseType';

type excelExportProps = {
	headers: SimpleTableColumnType[];
	fileName: string;
	datasource: BaseType[];
	hiddenColumns?: string[];
	renderCell?: {
		[key: string]: (row: BaseType) => any;
	};
};

export const excelExport = (props: excelExportProps) => {
	const { headers, fileName, datasource, hiddenColumns, renderCell } = props;
	const workbook = new excelJS.Workbook();
	const worksheet = workbook.addWorksheet('Sheet1');

	const rowHeader = headers.map((head) => head.label);
	worksheet.addRow(rowHeader);

	// Enable wrapText for all columns
	headers.forEach((_, index) => {
		worksheet.getColumn(index + 1).alignment = { wrapText: true };
	});

	datasource.forEach((data) => {
		const row: string[] = [];
		headers.forEach((column) => {
			if (renderCell) {
				if (
					Object.keys(renderCell).find((key) => {
						return key === column.key;
					})
				) {
					row.push(renderCell[column.key](data));
				} else {
					row.push(data[column.key]);
				}
			} else {
				row.push(data[column.key]);
			}
		});
		worksheet.addRow(row);
	});
	if (hiddenColumns) {
		hiddenColumns.forEach((col) => {
			worksheet.getColumn(col).hidden = true;
		});
	}
	workbook.xlsx
		.writeBuffer()
		.then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${fileName}.xlsx`))
		.catch((err) => console.log('Error writing excel export', err));
};
