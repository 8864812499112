import { useAxios } from './axiosConfig';
import { AssetType, PalletType } from '@/types/pallet';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { takeEvery, all, call, put } from 'redux-saga/effects';
import { assetActions, assetState } from '@/reducers/assetReducer';
import { InitialEnum, ListRequestType } from '@/types/baseType';
import { baseActions } from '@/reducers/baseReducer';

export const translateAssetType = (data: any[]) => {
	if (Array.isArray(data)) {
		const typeObject: AssetType[] = data.map((content) => {
			return new AssetType(content);
		});
		return typeObject;
	} else {
		return [];
	}
};

const getPalletAdmissionListRequest = (action: PayloadAction<ListRequestType<any>>) => {
	//서버에 요청을 보내는 부분.
	return useAxios.get(
		'asset/getPallets' +
			'?page=' +
			action.payload.page +
			'&size=' +
			action.payload.size +
			'&sort=id,asc',
	);
};

function* getPalletAdmissionList(action: PayloadAction<assetState>) {
	try {
		const response: AxiosResponse<any> = yield call(getPalletAdmissionListRequest, action as any);
		yield put(assetActions.getPalletAdmissionListSuccess(response.data));
	} catch (err) {
		console.dir(err);
	}
}

const getPalletListRequest = (action: PayloadAction<ListRequestType<any>>) => {
	//서버에 요청을 보내는 부분.
	return useAxios.get(
		'asset/init/' +
			InitialEnum.pallet +
			'?page=' +
			action.payload.page +
			'&size=' +
			action.payload.size,
	);
};

function* getPalletList(action: PayloadAction<assetState>) {
	try {
		const response: AxiosResponse<any> = yield call(getPalletListRequest, action as any);
		yield put(assetActions.getPalletListSuccess(response.data));
	} catch (err) {
		console.dir(err);
	}
}
const getTransactionListRequest = (action: PayloadAction<ListRequestType<any>>) => {
	//서버에 요청을 보내는 부분.
	return useAxios.get(
		'asset/init/' +
			InitialEnum.transaction +
			'?page=' +
			action.payload.page +
			'&size=' +
			action.payload.size +
			'&sort=id,desc',
	);
};

function* getTransactionList(action: PayloadAction<assetState>) {
	try {
		const response: AxiosResponse<any> = yield call(getTransactionListRequest, action as any);
		yield put(assetActions.getTransactionListSuccess(response.data));
	} catch (err) {
		console.dir(err);
	}
}

const getPeopleListRequest = (action: PayloadAction<ListRequestType<any>>) => {
	//서버에 요청을 보내는 부분.
	return useAxios.get(
		'asset/init/' +
			InitialEnum.vehicle +
			'?page=' +
			action.payload.page +
			'&size=' +
			action.payload.size,
	);
};

function* getPeopleList(action: PayloadAction<assetState>) {
	try {
		const response: AxiosResponse<any> = yield call(getPeopleListRequest, action as any);
		yield put(assetActions.getPeopleListSuccess(response.data));
	} catch (err) {
		console.dir(err);
	}
}

// const getAssetTypeListRequest = (action: PayloadAction<undefined>) => {
// 	//서버에 요청을 보내는 부분.
// 	return useAxios.get('assetType');
// };

// function* getAssetTypeList(action: PayloadAction<assetState>) {
// 	try {
// 		const response: AxiosResponse<any> = yield call(getAssetTypeListRequest, action as any);
// 		yield put(assetActions.getAssetTypeListSuccess(response.data));
// 	} catch (err) {
// 		console.dir(err);
// 	}
// }

const postPalletRequest = (action: PayloadAction<AssetType>) => {
	//서버에 요청을 보내는 부분.
	const entity = AssetType.toAssetEntity(action.payload);
	return useAxios.post('asset', entity);
};

function* postPallet(action: PayloadAction<assetState>) {
	try {
		const response: AxiosResponse<any> = yield call(postPalletRequest, action as any);
		yield put(assetActions.postPalletSuccess(response.data));
		yield put(baseActions.setAlert({ message: '생성 완료', type: 'success' }));
	} catch (err) {
		console.dir(err);
	}
}

const postTransactionRequest = (action: PayloadAction<AssetType[]>) => {
	//서버에 요청을 보내는 부분.
	return useAxios.post(
		'asset/list',
		action.payload.map((entity) => {
			return AssetType.toAssetEntity(entity);
		}),
	);
};

function* postTransaction(action: PayloadAction<assetState>) {
	try {
		const response: AxiosResponse<any> = yield call(postTransactionRequest, action as any);
		yield put(assetActions.postTransactionSuccess(response.data));
		yield put(baseActions.setAlert({ message: '출고증 생성 완료', type: 'success' }));
	} catch (err) {
		console.dir(err);
	}
}

const deleteAssetRequest = (action: PayloadAction<string>) => {
	//서버에 요청을 보내는 부분.
	return useAxios.delete('asset/' + action.payload);
};

function* deleteAsset(action: PayloadAction<assetState>) {
	try {
		const response: AxiosResponse<any> = yield call(deleteAssetRequest, action as any);
		yield put(assetActions.deleteAssetSuccess(response.data));
		yield put(baseActions.setAlert({ message: '삭제 완료', type: 'success' }));
	} catch (err) {
		console.dir(err);
	}
}
export function* assetSaga() {
	yield takeEvery(assetActions.getPalletList.type, getPalletList);
	yield takeEvery(assetActions.getPalletAdmissionList.type, getPalletAdmissionList);
	yield takeEvery(assetActions.getTransactionList.type, getTransactionList);
	yield takeEvery(assetActions.getPeopleList.type, getPeopleList);
	yield takeEvery(assetActions.postPallet.type, postPallet);
	yield takeEvery(assetActions.postTransaction.type, postTransaction);
	yield takeEvery(assetActions.deleteAsset.type, deleteAsset);
}
