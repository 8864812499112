import { store } from './store';
import App from '@/App';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container as Element);
document.documentElement.lang = 'ko';

root.render(
	<HashRouter>
		<Provider store={store}>
			<App />
		</Provider>
	</HashRouter>,
);
