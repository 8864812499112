import { AlertType, ListRequestType, ResponseType } from '@/types/baseType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface baseState {
	alert: AlertType;
	isVerified: boolean;
}

const initialState = {
	alert: {} as AlertType,
	isVerified: false,
};

const baseReducers = {
	initState: (state: baseState) => {
		state.alert = {} as AlertType;
	},
	setAlert: (state: baseState, action: PayloadAction<AlertType>) => {
		state.alert = action.payload;
	},
	setVerified: (state: baseState, action: PayloadAction<boolean>) => {
		state.isVerified = action.payload;
	},
};
export const basetSlice = createSlice({
	name: 'base',
	initialState,
	reducers: baseReducers,
});

export const baseActions = basetSlice.actions!;

export default basetSlice.reducer;
