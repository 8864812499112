import { ListRequestType, ResponseType } from '@/types/baseType';
import {
	AssetType,
	PalletAdmissionType,
	PalletType,
	PostAssetType,
	PostPalletType,
} from '@/types/pallet';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface assetState {
	data_list: AssetType[];
	created_asset: AssetType | null;
	created_assets: AssetType[] | [];
	countContents: number;
	pallet_list: PalletAdmissionType[];
	delete_success: boolean;
}

const initialState = {
	data_list: [] as AssetType[],
	created_asset: null,
	created_assets: [] as AssetType[],
	countContents: 0,
	pallet_list: [] as PalletAdmissionType[],
	delete_success: false,
};

const assetReducers = {
	initState: (state: assetState) => {
		state.data_list = [];
	},
	getPalletAdmissionList: (
		state: assetState,
		action: PayloadAction<ListRequestType<undefined>>,
	) => {},
	getPalletAdmissionListSuccess: (
		state: assetState,
		action: PayloadAction<ResponseType<PalletAdmissionType>>,
	) => {
		state.pallet_list = action.payload.content;
		state.countContents = action.payload.totalElements;
	},
	getPalletList: (state: assetState, action: PayloadAction<ListRequestType<undefined>>) => {},
	getPalletListSuccess: (state: assetState, action: PayloadAction<ResponseType<AssetType>>) => {
		state.data_list = action.payload.content;
		state.countContents = action.payload.totalElements;
	},
	getTransactionList: (state: assetState, action: PayloadAction<ListRequestType<undefined>>) => {},
	getTransactionListSuccess: (state: assetState, action: PayloadAction<ResponseType<AssetType>>) => {
		state.data_list = action.payload.content;
		state.countContents = action.payload.totalElements;
	},
	getPeopleList: (state: assetState, action: PayloadAction<ListRequestType<undefined>>) => {},
	getPeopleListSuccess: (state: assetState, action: PayloadAction<ResponseType<AssetType>>) => {
		state.data_list = action.payload.content;
		state.countContents = action.payload.totalElements;
	},
	// getAssetTypeList: (state: assetState, action: PayloadAction<undefined>) => {},
	// getAssetTypeListSuccess: (state: assetState, action: PayloadAction<ResponseType<AssetType>>) => {
	// 	state.assetType_list = action.payload.content;
	// },
	postPallet: (state: assetState, action: PayloadAction<PostAssetType>) => {},
	postPalletSuccess: (state: assetState, action: PayloadAction<AssetType>) => {
		state.created_asset = action.payload;
	},
	postTransaction: (state: assetState, action: PayloadAction<PostAssetType[]>) => {},
	postTransactionSuccess: (state: assetState, action: PayloadAction<AssetType[]>) => {
		state.created_assets = action.payload;
	},
	deleteAsset: (state: assetState, action: PayloadAction<string>) => {
		state.delete_success = false;
	},
	deleteAssetSuccess: (state: assetState, action: PayloadAction<boolean>) => {
		state.delete_success = action.payload;
	},
};
export const assetSlice = createSlice({
	name: 'asset',
	initialState,
	reducers: assetReducers,
});

export const assetActions = assetSlice.actions!;

export default assetSlice.reducer;
