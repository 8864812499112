import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { assetSlice } from './reducers/assetReducer';
import { assetSaga } from './sagas/assetSaga';
import { admissionSaga } from './sagas/admissionSaga';
import { admissionActions, admissionSlice } from './reducers/historyReducer';
import { basetSlice } from './reducers/baseReducer';

const sagaMiddleware = createSagaMiddleware();

function* rootsaga() {
	yield all([fork(assetSaga)]);
	yield all([fork(admissionSaga)]);
}

export const store = configureStore({
	reducer: {
		asset: assetSlice.reducer,
		history: admissionSlice.reducer,
		base: basetSlice.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware),
	devTools: true,
});

sagaMiddleware.run(rootsaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
