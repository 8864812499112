const breakpoints = {
	values: {
		xs: 0,
		sm: 810,
		md: 1080,
		lg: 1440,
		xl: 1920,
	},
};

export default breakpoints;
