import { SimpleTable, SimpleTableColumnType } from '@/components/Table';
import { RootState } from '@/store';
import {
	AddCircleOutline,
	EditOutlined,
	LocalPrintshopOutlined,
	RemoveCircleOutline,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { TransactionType } from '@/types/transaction';
import { sendPalletPrintZpl, sendTransactionPrintZpl } from '@/components/ZebraZPLPrint';
import { assetActions } from '@/reducers/assetReducer';
import { AssetType, PostAssetType } from '@/types/pallet';
import { InitialEnum } from '@/types/baseType';
import Cookies from 'js-cookie';
import { baseActions } from '@/reducers/baseReducer';
import { ConfirmBox } from '@/components/ConfirmBox';

const tableColums: SimpleTableColumnType[] = [
	{ label: '', key: 'check', width: '40px', align: 'left' },
	{ label: 'ID', key: 'id', width: '10%', align: 'left', sort: (row: AssetType) => row.id },
	{
		label: '거래처',
		key: 'company',
		width: '15%',
		align: 'left',
		sort: (row: AssetType) => (row.transactions ? row.transactions[0].company : ''),
	},
	{ label: '현장주소', key: 'site', width: '27%', align: 'left' },
	{ label: '연락처', key: 'receiverTel', width: '13%', align: 'left' },
	{ label: '비고', key: 'note', width: '31%', align: 'left' },
];

type TransactionDialogProps = {
	dialogOpen: boolean;
	setDialogOpen: React.Dispatch<React.SetStateAction<(EventTarget & HTMLButtonElement) | boolean>>;
	isModify: boolean;
	clickedRow: AssetType | null;
	setClickedRow: React.Dispatch<
		React.SetStateAction<(EventTarget & HTMLButtonElement) | AssetType | null>
	>;
};
// TransactionType에 index를 추가한 타입을 정의
const TransactionCreateDialog = (props: TransactionDialogProps) => {
	const dispatch = useDispatch();
	const { dialogOpen, setDialogOpen, isModify, clickedRow, setClickedRow } = props;
	// const [transactionDataList, setTransactionDataList] = useState<IndexedTransactionType[]>([
	// 	{ ...new TransactionType(), index: -1 },
	// ]);
	const [transactionDataList, setTransactionDataList] = useState<TransactionType[]>([
		new TransactionType(),
	]);
	const { created_assets } = useSelector((state: RootState) => state.asset);
	const [rowsPage, setRowsPage] = useState<number>(20);
	const [confirmAnchorEl, setConfirmAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(
		null,
	);
	const [itemIndex, setItemIndex] = useState<number>(-1);

	useEffect(() => {
		if (clickedRow) {
			let temp = [...transactionDataList];
			if (clickedRow.transactions) {
				temp[0] = { ...clickedRow.transactions[0] };
				setTransactionDataList([...temp]);
			}
		}
	}, [clickedRow]);

	useEffect(() => {
		if (created_assets.length > 0) {
			dispatch(assetActions.getTransactionList({ page: 0, size: rowsPage }));
			setClickedRow(null);
		}
	}, [created_assets]);

	return (
		<Box>
			<Dialog
				open={dialogOpen}
				aria-labelledby="form-dialog-title"
				onClose={(e, reason) => {
					if (reason === 'escapeKeyDown') {
						setDialogOpen(false);
					}
				}}
				fullWidth={true}
				maxWidth="xl"
			>
				<DialogContent>
					<Box sx={{ p: '10px' }}>
						<ConfirmBox
							element={confirmAnchorEl}
							setElement={setConfirmAnchorEl}
							onOk={() => {
								if (clickedRow) {
									dispatch(assetActions.deleteAsset(clickedRow?.id));
									setConfirmAnchorEl(null);
									setDialogOpen(false);
								}
							}}
							onCancel={() => {
								setConfirmAnchorEl(null);
							}}
							message="선택 항목을 삭제하시겠습니까?"
						/>
						<Grid container>
							<Grid item>
								<Typography variant="h1">{'출고증 ' + (isModify ? '수정' : '등록')}</Typography>
							</Grid>
						</Grid>
						<Grid container alignItems={'center'} sx={{ mt: '25px' }} spacing={2}>
							<Grid item xs={0.2}>
								<Typography variant="body1"></Typography>
							</Grid>
							<Grid item xs={1.4}>
								<Typography variant="body1">
									{'거래처'}
									<span style={{ color: 'red' }}>*</span>
								</Typography>
							</Grid>
							<Grid item xs={2.7}>
								<Typography variant="body1">
									{'현장주소'}
									<span style={{ color: 'red' }}>*</span>
								</Typography>
							</Grid>
							<Grid item xs={1}>
								<Typography variant="body1">{'인수자'}</Typography>
							</Grid>
							<Grid item xs={1.2}>
								<Typography variant="body1">{'연락처'}</Typography>
							</Grid>
							<Grid item xs={0.5}>
								<Typography variant="body1">{'BF'}</Typography>
							</Grid>
							<Grid item xs={0.5}>
								<Typography variant="body1">{'SF'}</Typography>
							</Grid>
							<Grid item xs={0.5}>
								<Typography variant="body1">{'MF'}</Typography>
							</Grid>
							<Grid item xs={0.5}>
								<Typography variant="body1">{'GL'}</Typography>
							</Grid>
							<Grid item xs={2.9}>
								<Typography variant="body1">{'비고'}</Typography>
							</Grid>
							<Grid item xs={0.4}></Grid>
						</Grid>
						{transactionDataList.map((tr, index) => {
							return (
								<Grid key={'tr_' + tr.id} container alignItems={'center'} sx={{ mt: '25px' }}>
									<Grid item xs={0.2}>
										<Typography variant="body1">{index + 1}</Typography>
									</Grid>
									<Grid item xs={1.4}>
										<TextField
											key={'TextField_company_' + tr.id}
											sx={{ width: '100%' }}
											onChange={(e) => {
												let temp = [...transactionDataList];
												temp[index].company = e.target.value;
												setTransactionDataList([...temp]);
											}}
											error={tr.company === ''}
											helperText={tr.company === '' ? '필수 입력값입니다.' : ''}
											defaultValue={
												isModify ? (clickedRow?.transactions ? clickedRow?.transactions[0].company : '') : ''
											}
										></TextField>
									</Grid>
									<Grid item xs={2.7}>
										<TextField
											key={'TextField_site_' + tr.id}
											sx={{ width: '100%' }}
											onChange={(e) => {
												let temp = [...transactionDataList];
												temp[index].site = e.target.value;
												setTransactionDataList([...temp]);
											}}
											error={tr.site === ''}
											helperText={tr.site === '' ? '필수 입력값입니다.' : ''}
											defaultValue={
												isModify ? (clickedRow?.transactions ? clickedRow?.transactions[0].site : '') : ''
											}
										></TextField>
									</Grid>
									<Grid item xs={1}>
										<TextField
											key={'TextField_receiver_' + tr.id}
											sx={{ width: '100%' }}
											onChange={(e) => {
												let temp = [...transactionDataList];
												temp[index].receiverName = e.target.value;
												setTransactionDataList([...temp]);
											}}
											defaultValue={
												isModify
													? clickedRow?.transactions
														? clickedRow?.transactions[0].receiverName
														: ''
													: ''
											}
										></TextField>
									</Grid>
									<Grid item xs={1.2}>
										<TextField
											key={'TextField_tel_' + tr.id}
											sx={{ width: '100%' }}
											onChange={(e) => {
												let temp = [...transactionDataList];
												temp[index].receiverTel = e.target.value;
												setTransactionDataList([...temp]);
											}}
											defaultValue={
												isModify
													? clickedRow?.transactions
														? clickedRow?.transactions[0].receiverTel
														: ''
													: ''
											}
										></TextField>
									</Grid>
									<Grid item xs={0.5}>
										<TextField
											key={'TextField_bf_' + tr.id}
											sx={{ width: '100%' }}
											onChange={(e) => {
												let temp = [...transactionDataList];
												temp[index].bf = Number(e.target.value);
												setTransactionDataList([...temp]);
											}}
											defaultValue={
												isModify ? (clickedRow?.transactions ? clickedRow?.transactions[0].bf : '') : ''
											}
										></TextField>
									</Grid>
									<Grid item xs={0.5}>
										<TextField
											key={'TextField_sf_' + tr.id}
											sx={{ width: '100%' }}
											onChange={(e) => {
												let temp = [...transactionDataList];
												temp[index].sf = Number(e.target.value);
												setTransactionDataList([...temp]);
											}}
											defaultValue={
												isModify ? (clickedRow?.transactions ? clickedRow?.transactions[0].sf : '') : ''
											}
										></TextField>
									</Grid>
									<Grid item xs={0.5}>
										<TextField
											key={'TextField_mf_' + tr.id}
											sx={{ width: '100%' }}
											onChange={(e) => {
												let temp = [...transactionDataList];
												temp[index].mf = Number(e.target.value);
												setTransactionDataList([...temp]);
											}}
											defaultValue={
												isModify ? (clickedRow?.transactions ? clickedRow?.transactions[0].mf : '') : ''
											}
										></TextField>
									</Grid>
									<Grid item xs={0.5}>
										<TextField
											key={'TextField_gl_' + tr.id}
											sx={{ width: '100%' }}
											onChange={(e) => {
												let temp = [...transactionDataList];
												temp[index].glass = Number(e.target.value);
												setTransactionDataList([...temp]);
											}}
											defaultValue={
												isModify ? (clickedRow?.transactions ? clickedRow?.transactions[0].glass : '') : ''
											}
										></TextField>
									</Grid>
									<Grid item xs={2.6}>
										<TextField
											key={'TextField_note_' + tr.id}
											sx={{ width: '100%' }}
											multiline
											onChange={(e) => {
												let temp = [...transactionDataList];
												temp[index].note = e.target.value;
												setTransactionDataList([...temp]);
											}}
											onKeyDown={(e) => {
												if (e.key == 'Tab') {
													let temp = [...transactionDataList];
													temp.splice(index + 1, 0, {
														...new TransactionType(),
														index: transactionDataList.length,
													});
													setTransactionDataList([...temp]);
												}
											}}
											defaultValue={
												isModify ? (clickedRow?.transactions ? clickedRow?.transactions[0].note : '') : ''
											}
										></TextField>
									</Grid>
									<Grid item xs={0.4}>
										{isModify == false ? (
											<Button
												onClick={(e) => {
													if (transactionDataList.length > 1) {
														let temp = [
															...transactionDataList.filter((a) => {
																return a.id !== tr.id;
															}),
														];
														setTransactionDataList([...temp]);
													}
												}}
											>
												<RemoveCircleOutline color="error" />
											</Button>
										) : null}
									</Grid>
									<Grid item xs={0.4}>
										{isModify == false && index == transactionDataList.length - 1 ? (
											<Button
												onClick={(e) => {
													let temp = [...transactionDataList];
													temp.splice(index + 1, 0, {
														...new TransactionType(),
														id: itemIndex - 1 + '',
													});
													setItemIndex(itemIndex - 1);
													setTransactionDataList([...temp]);
												}}
											>
												<AddCircleOutline />
											</Button>
										) : null}
									</Grid>
								</Grid>
							);
						})}
					</Box>
				</DialogContent>
				<DialogActions>
					{clickedRow ? (
						<Button
							color="error"
							onClick={(e) => {
								setConfirmAnchorEl(e.currentTarget);
							}}
						>
							삭제
						</Button>
					) : null}
					<Button
						onClick={(e) => {
							setDialogOpen(false);
							if (isModify) {
								if (clickedRow) {
									dispatch(
										assetActions.postTransaction([
											{
												...clickedRow,
												name: transactionDataList[0].site,
												transactions: [{ ...transactionDataList[0] }],
											} as AssetType,
										]),
									);
								}
							} else {
								dispatch(
									assetActions.postTransaction(
										transactionDataList.map((data) => {
											return {
												name: data.site,
												status: 'IN',
												type: '출고',
												initial: InitialEnum.transaction,
												note: '',
												transactions: [
													{
														company: data.company,
														site: data.site,
														bf: data.bf,
														sf: data.sf,
														mf: data.mf,
														glass: data.glass,
														note: data.note,
														receiverName: data.receiverName,
														receiverTel: data.receiverTel,
													},
												],
											} as PostAssetType;
										}),
									),
								);
							}
							setTransactionDataList([{ ...new TransactionType(), index: -1 }]);
						}}
					>
						저장
					</Button>
					<Button
						onClick={(e) => {
							setDialogOpen(false);
							setTransactionDataList([{ ...new TransactionType(), index: -1 }]);
						}}
					>
						취소
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

const TransactionBoard = () => {
	const dispatch = useDispatch();
	const [transactionList, setTransactionList] = useState<AssetType[]>([]);
	const [checkedTransactions, setCheckedTransactions] = useState<AssetType[]>([]);
	const [clickedRow, setClickedRow] = useState<AssetType | null>(null);
	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
	const [page, setPage] = useState<number>(0);
	const [rowsPage, setRowsPage] = useState<number>(20);
	const { data_list, countContents, delete_success } = useSelector(
		(state: RootState) => state.asset,
	);

	useEffect(() => {
		const cookie_page_str = Cookies.get('page');
		if (cookie_page_str) {
			const cookie_page = Number(cookie_page_str);
			if (cookie_page === page) {
				dispatch(assetActions.getTransactionList({ page: page, size: rowsPage }));
			}
		}
	}, [page, rowsPage]);

	useEffect(() => {
		if (delete_success) {
			dispatch(assetActions.getTransactionList({ page: page, size: rowsPage }));
		}
	}, [delete_success]);

	useEffect(() => {
		setTransactionList([
			...data_list.map((entity: AssetType) => {
				if (entity.shippingOrders) {
					return { ...entity, transactions: [new TransactionType(entity.shippingOrders[0])] };
				} else {
					return entity;
				}
			}),
		]);
	}, [data_list]);

	useEffect(() => {
		if (createModalOpen == false) {
			setClickedRow(null);
		}
	}, [createModalOpen]);

	const checkTransaction = (row: AssetType) => {
		if (checkedTransactions.filter((p) => p.id == row.id).length > 0) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<Box>
			<TransactionCreateDialog
				dialogOpen={createModalOpen}
				setDialogOpen={setCreateModalOpen}
				isModify={clickedRow != null}
				clickedRow={clickedRow}
				setClickedRow={setClickedRow}
			/>
			<Grid container justifyContent={'space-between'}>
				<Grid item xs></Grid>
				<Grid item sx={{ mr: '30px' }}>
					<Button
						onClick={() => {
							setCreateModalOpen(true);
						}}
					>
						<AddCircleOutline /> 등록
					</Button>
				</Grid>
				<Grid item>
					<Button
						onClick={() => {
							sendTransactionPrintZpl(checkedTransactions, 1);
							dispatch(baseActions.setAlert({ message: '프린트 전송 완료', type: 'success' }));
						}}
					>
						<LocalPrintshopOutlined /> 출력
					</Button>
				</Grid>
			</Grid>
			<Grid container sx={{ mt: '30px', border: '1px solid #3c3c3c' }}>
				<Grid item xs>
					<SimpleTable
						columns={[...tableColums] as SimpleTableColumnType[]}
						datasource={transactionList}
						pageRows={rowsPage}
						totalRows={countContents}
						getPage={setPage}
						rowsPage={[20, 40, 80]}
						setRowsPage={setRowsPage}
						onRowClick={(row: AssetType, e: any) => {
							if (e?.target.type !== 'checkbox') {
								setClickedRow(row);
								setCreateModalOpen(true);
							}
						}}
						renderCell={{
							check: (row: AssetType) => {
								return (
									<Checkbox
										checked={checkTransaction(row)}
										onChange={(e) => {
											if (e.target.checked) {
												let temp = [...checkedTransactions];
												temp.push(row);
												setCheckedTransactions(temp);
											} else {
												let temp = checkedTransactions.filter((pallet) => pallet.id !== row.id);
												setCheckedTransactions(temp);
											}
											e.stopPropagation();
										}}
										size="small"
									/>
								);
							},
							company: (row: AssetType) => {
								if (row.transactions) {
									return <Typography>{row.transactions[0]?.company}</Typography>;
								} else {
									return '-';
								}
							},
							site: (row: AssetType) => {
								if (row.transactions) {
									return <Typography>{row.transactions[0]?.site}</Typography>;
								} else {
									return '-';
								}
							},
							receiverTel: (row: AssetType) => {
								if (row.transactions) {
									return row.transactions[0]?.receiverTel;
								} else {
									return '-';
								}
							},
							note: (row: AssetType) => {
								if (row.transactions) {
									return row.transactions[0]?.note;
								} else {
									return '-';
								}
							},
							delivery: (row: AssetType) => {
								// return row.delivery.name;
							},
							deliveryTel: (row: AssetType) => {
								// return row.delivery.tel;
							},
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default TransactionBoard;
