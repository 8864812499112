import { UnfoldMoreOutlined, FilterListOffOutlined } from '@mui/icons-material';
import { ConfirmBox } from './ConfirmBox';
import { BaseType } from '@/types/baseType';
import {
	Backdrop,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	styled,
} from '@mui/material';
import {
	ForwardedRef,
	ReactNode,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const StyledTableRow = styled(TableRow)({
	'&:hover': {
		background: 'rgba( 11,138,227, 0.2)',
	},
});

export type SimpleTableColumnType = {
	label: string;
	key: string;
	minWidth?: string | number;
	width?: string | number;
	hide?: boolean;
	customComponent?: () => ReactNode;
	align?: 'left' | 'right' | 'center';
	sort?: (row: BaseType) => any;
};

type simpleTableProps = {
	columns: SimpleTableColumnType[];
	datasource: BaseType[];
	renderCell?: {
		[key: string]: (row: BaseType) => any;
	};
	onRowClick?: (row: BaseType, e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => any;
	additionalRow?: (row: BaseType, index: number) => any;
	maxHeight?: string | number;
	deletable?: boolean;
	pageRows: number;
	getPage: (page: number) => any;
	headerStyle?: any;
	rowNum?: boolean;
	hidePagination?: boolean;
	pageLoading?: boolean;
	onDelete?: (list: number[]) => void;
	onScroll?: () => void;
	print?: boolean;
	border?: boolean;
	defaultDelete?: boolean;
	deleteCondition?: (row: BaseType) => boolean;
	selectedRows?: number[];
	totalRows?: number;
	rowsPage?: number[];
	setRowsPage?: (rowsPage: number) => any;
	setSort?: (sort: string) => any;
};
export interface simpleTableRef {
	getLoading: () => boolean;
}
export const SimpleTable = forwardRef(
	(props: simpleTableProps, ref: ForwardedRef<simpleTableRef>) => {
		const {
			columns,
			datasource,
			renderCell,
			onRowClick,
			additionalRow,
			maxHeight,
			deletable,
			pageRows,
			getPage,
			headerStyle,
			rowNum,
			hidePagination,
			pageLoading,
			onDelete,
			onScroll,
			print,
			border,
			defaultDelete,
			deleteCondition,
			selectedRows,
			totalRows,
			rowsPage,
			setRowsPage,
			setSort,
		} = props;
		const [tableData, setTableData] = useState<BaseType[]>([]);
		const [checkDelete, setCheckDelete] = useState<boolean>(defaultDelete ? defaultDelete : false);
		const [allChecked, setAllChecked] = useState<boolean>(false);
		const [checkedList, setCheckedList] = useState<number[]>([]);
		const [confirmAnchorEl, setConfirmAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(
			null,
		);
		const [page, setPage] = useState<number>(0);
		const [loading, setLoading] = useState<boolean>(true);
		const [sortColumn, setSortColumn] = useState<{ column: SimpleTableColumnType; sort: boolean }>();

		useEffect(() => {
			const cookie_page = Cookies.get('page');
			if (cookie_page) {
				setPage(Number(cookie_page));
				getPage(Number(cookie_page));
			}
		}, []);

		useEffect(() => {
			const test: JSX.Element[] = [];
			tableData.forEach((object, index) => {
				if (totalRows ? true : index > pageRows * page - 1 && index < pageRows * (page + 1)) {
					test.push(
						<>
							<StyledTableRow
								key={'table_row_' + index}
								onClick={(e) => {
									if (onRowClick) {
										onRowClick(object, e);
									} else {
										e.stopPropagation();
									}
								}}
								sx={{
									backgroundColor: index % 2 === 0 ? '#EBF2F4' : '#FFF',
								}}
							>
								{checkDelete || defaultDelete ? (
									<TableCell key={'table_head_check'} width={'40px'}>
										<Checkbox
											sx={{
												color: 'red',
												'&.Mui-checked': {
													color: 'red',
												},
											}}
											size="small"
											checked={checkedList.includes(object.seq)}
											onChange={(e) => {
												if (deleteCondition) {
													if (deleteCondition(object)) {
														if (e.target.checked) {
															setCheckedList([...checkedList, object.seq]);
														} else {
															var temp = [...checkedList];
															checkedList.forEach((item, index) => {
																if (item === object.seq) temp.splice(index, 1);
															});
															setCheckedList([...temp]);
														}
													}
												} else {
													if (e.target.checked) {
														setCheckedList([...checkedList, object.seq]);
													} else {
														var temp = [...checkedList];
														checkedList.forEach((item, index) => {
															if (item === object.seq) temp.splice(index, 1);
														});
														setCheckedList([...temp]);
													}
												}
											}}
										/>
									</TableCell>
								) : null}
								{rowNum ? (
									<TableCell key={'table_row_index'} width={'40px'}>
										<Typography variant="caption">{index + 1}</Typography>
									</TableCell>
								) : null}
								{columns.map((column: SimpleTableColumnType, index) => {
									if (column.hide === true) {
										return null;
									}
									if (renderCell) {
										if (
											Object.keys(renderCell).find((key) => {
												return key === column.key;
											})
										) {
											return (
												<TableCell
													key={'table_row_' + index + column.key}
													sx={{ borderBottom: '1px solid ' + (print ? '#000' : '#D5D5D5') }}
													colSpan={index >= columns.length - 1 ? 2 : 1}
												>
													{renderCell[column.key](object)}
												</TableCell>
											);
										} else {
											return (
												<TableCell
													key={'table_row_' + index + column.key}
													sx={{ borderBottom: '1px solid ' + (print ? '#000' : '#D5D5D5') }}
													colSpan={index >= columns.length - 1 ? 2 : 1}
												>
													<Typography>{object[column.key]}</Typography>
												</TableCell>
											);
										}
									} else {
										if (typeof object[column.key] === 'object') {
											return (
												<TableCell
													key={'table_row_' + index + column.key}
													sx={{ borderBottom: '1px solid ' + (print ? '#000' : '#D5D5D5') }}
													colSpan={index >= columns.length - 1 ? 2 : 1}
												>
													<Typography>{'-'}</Typography>
												</TableCell>
											);
										} else {
											return (
												<TableCell
													key={'table_row_' + index + column.key}
													sx={{ borderBottom: '1px solid ' + (print ? '#000' : '#D5D5D5') }}
													colSpan={index >= columns.length - 1 ? 2 : 1}
												>
													<Typography>{object[column.key]}</Typography>
												</TableCell>
											);
										}
									}
								})}
							</StyledTableRow>
							{additionalRow ? (
								additionalRow(object, index) ? (
									<StyledTableRow
										key={'table_row_additional' + index}
										onClick={(e) => {
											if (onRowClick) {
												onRowClick(object);
											} else {
												e.stopPropagation();
											}
										}}
										sx={{
											backgroundColor: index % 2 === 0 ? '#EBF2F4' : '#FFF',
										}}
									>
										{checkDelete || defaultDelete ? <TableCell></TableCell> : null}
										<TableCell colSpan={columns.length + 1}>{additionalRow(object, index)}</TableCell>
									</StyledTableRow>
								) : null
							) : null}
						</>,
					);
				}
			});
			setLoading(false);
		}, [tableData]);

		useEffect(() => {
			setTableData([...datasource]);
			if (datasource.length < 1) setLoading(false);
		}, [datasource]);

		const isNumericString = (str: string) => {
			if (!isNaN(Number(str)) && !isNaN(parseFloat(str))) {
				return Number(str);
			} else {
				return str;
			}
		};

		useEffect(() => {
			if (sortColumn) {
				let temp = [...tableData];
				temp.sort((a, b) => {
					if (sortColumn.column.sort) {
						let a_ = sortColumn.column.sort(a) !== undefined ? sortColumn.column.sort(a) : '';
						let b_ = sortColumn.column.sort(b) !== undefined ? sortColumn.column.sort(b) : '';
						a_ = isNumericString(a_);
						b_ = isNumericString(b_);
						if (sortColumn.sort) {
							// 숫자와 문자열을 각각 비교
							if (typeof a_ === 'number' && typeof b_ === 'number') {
								return a_ - b_;
							} else if (typeof a_ === 'string' && typeof b_ === 'string') {
								return a_.localeCompare(b_);
							} else {
								return a_ - b_;
							}
						} else {
							// 숫자와 문자열을 각각 비교
							if (typeof a_ === 'number' && typeof b_ === 'number') {
								return b_ - a_;
							} else if (typeof a_ === 'string' && typeof b_ === 'string') {
								return b_.localeCompare(a_);
							} else {
								return b_ - a_;
							}
						}
					} else {
						return -1;
					}
				});
				setTableData([...temp]);
			}
		}, [sortColumn]);

		useEffect(() => {
			if (deleteCondition) {
				const temp = datasource.filter((data) => deleteCondition(data));
				if (checkedList.length < temp.length) {
					setAllChecked(false);
				}
			} else {
				if (checkedList.length < datasource.length) {
					setAllChecked(false);
				}
			}
		}, [checkedList]);

		const getLoading = () => {
			return loading;
		};

		useImperativeHandle(ref, () => ({
			getLoading,
		}));

		return (
			<>
				<Backdrop open={pageLoading ? pageLoading : false} sx={{ zIndex: 999 }}>
					<CircularProgress />
				</Backdrop>
				<Box sx={{ width: '100%', backgroundColor: '#FFF', borderRadius: '38px' }}>
					<ConfirmBox
						element={confirmAnchorEl}
						setElement={setConfirmAnchorEl}
						onOk={() => {
							setCheckDelete(!checkDelete);
							if (onDelete) onDelete(checkedList);
							setConfirmAnchorEl(null);
						}}
						onCancel={() => {
							setCheckDelete(!checkDelete);
							setConfirmAnchorEl(null);
						}}
						message="선택 항목을 삭제하시겠습니까?"
					/>
					<TableContainer sx={{ width: '100%', maxHeight: maxHeight }} onScroll={onScroll}>
						<Table stickyHeader>
							<TableHead>
								<TableRow key={'table_head'}>
									{checkDelete || defaultDelete ? (
										<TableCell key={'table_head_check'} sx={{ p: '0px' }}>
											<FormControl>
												<FormControlLabel
													sx={{
														m: '0px',
														'& .MuiFormControlLabel-label': {
															fontSize: '14px',
														},
													}}
													control={
														<Checkbox
															sx={{
																color: 'red',
																'&.Mui-checked': {
																	color: 'red',
																},
															}}
															checked={allChecked}
															size="small"
															onChange={(e) => {
																setAllChecked(e.target.checked);
																if (e.target.checked) {
																	setCheckedList([
																		...datasource
																			.map((item) => {
																				if (deleteCondition) {
																					if (deleteCondition(item)) {
																						return item.seq;
																					}
																				} else return item.seq;
																			})
																			.filter((data) => data !== undefined),
																	]);
																} else {
																	setCheckedList([]);
																}
															}}
														/>
													}
													label="전체"
													labelPlacement="top"
												/>
											</FormControl>
										</TableCell>
									) : null}
									{rowNum ? (
										<TableCell
											key={'table_head_index'}
											sx={{
												borderBottom: '1px solid ' + (print ? '#000' : '#D5D5D5'),
												p: print ? '1px' : '3px',
												border: border ? '0.01px solid ' + (print ? '#3c3c3c' : '#D5D5D5') : '',
											}}
										></TableCell>
									) : null}
									{columns.map((column, index) => {
										if (column.hide === true) {
											return null;
										}
										return (
											<TableCell
												key={'table_head_' + column.key + '_' + index}
												sx={{
													minWidth: column.minWidth ? column.minWidth : '10px',
													borderBottom: '1px solid ' + (print ? '#000' : '#D5D5D5'),
													p: print ? '1px' : '3px',
													border: border ? '0.01px solid ' + (print ? '#3c3c3c' : '#D5D5D5') : '',
													width: column.width ? column.width : '100%',
												}}
												width={'100%'}
											>
												{column.customComponent ? (
													column.customComponent()
												) : (
													<Grid container justifyContent={'center'} alignItems={'center'}>
														<Typography
															variant="body1"
															sx={{ ...headerStyle }}
															align={column.align ? column.align : 'center'}
														>
															{' '}
															{column.label}
														</Typography>
														{column.sort ? (
															<UnfoldMoreOutlined
																fontSize="small"
																onClick={() => {
																	if (sortColumn) {
																		if (sortColumn.column.key === column.key) {
																			setSortColumn({ ...sortColumn, sort: !sortColumn.sort });
																		} else {
																			setSortColumn({ column: column, sort: !sortColumn.sort });
																		}
																		if (setSort) setSort(column.key + ',' + (sortColumn.sort ? 'asc' : 'desc'));
																	} else {
																		setSortColumn({ column: column, sort: true });
																	}
																}}
															/>
														) : null}
													</Grid>
												)}
											</TableCell>
										);
									})}
									{deletable === true ? (
										<TableCell
											key={'table_head_delte'}
											width={'40px'}
											sx={{
												borderBottom: '1px solid ' + (print ? '#000' : '#D5D5D5'),
											}}
										>
											<Button
												color="error"
												variant="contained"
												onClick={(e) => {
													if (checkDelete || defaultDelete) {
														setConfirmAnchorEl(e.currentTarget);
													} else {
														setCheckDelete(!checkDelete);
													}
												}}
											>
												삭제
											</Button>
										</TableCell>
									) : null}
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData.length > 0 ? (
									tableData.map((object, index) => {
										if (totalRows ? true : index > pageRows * page - 1 && index < pageRows * (page + 1)) {
											return (
												<>
													<StyledTableRow
														key={'table_row_' + index}
														onClick={(e) => {
															if (onRowClick) {
																onRowClick(object, e);
															} else {
																e.stopPropagation();
															}
														}}
														sx={{
															backgroundColor: selectedRows
																? selectedRows.includes(object.seq)
																	? '#C2EBBF'
																	: index % 2 === 0
																		? '#EBF2F4'
																		: '#FFF'
																: index % 2 === 0
																	? '#EBF2F4'
																	: '#FFF',
															borderBottom: '1px solid ' + (print ? '#000' : '#D5D5D5'),
														}}
													>
														{checkDelete || defaultDelete ? (
															<TableCell
																key={'table_head_check_' + index}
																width={'40px'}
																sx={{
																	p: '0px',
																}}
															>
																{deleteCondition ? (
																	deleteCondition(object) ? (
																		<Checkbox
																			sx={{
																				color: 'red',
																				'&.Mui-checked': {
																					color: 'red',
																				},
																			}}
																			size="small"
																			checked={checkedList.includes(object.seq)}
																			onChange={(e) => {
																				if (deleteCondition) {
																					if (deleteCondition(object)) {
																						if (e.target.checked) {
																							setCheckedList([...checkedList, object.seq]);
																						} else {
																							var temp = [...checkedList];
																							checkedList.forEach((item, index) => {
																								if (item === object.seq) temp.splice(index, 1);
																							});
																							setCheckedList([...temp]);
																						}
																					}
																				} else {
																					if (e.target.checked) {
																						setCheckedList([...checkedList, object.seq]);
																					} else {
																						var temp = [...checkedList];
																						checkedList.forEach((item, index) => {
																							if (item === object.seq) temp.splice(index, 1);
																						});
																						setCheckedList([...temp]);
																					}
																				}
																			}}
																			onClick={(e) => {
																				e.stopPropagation();
																			}}
																		/>
																	) : null
																) : (
																	<Checkbox
																		sx={{
																			color: 'red',
																			'&.Mui-checked': {
																				color: 'red',
																			},
																		}}
																		size="small"
																		checked={checkedList.includes(object.seq)}
																		onChange={(e) => {
																			if (e.target.checked) {
																				setCheckedList([...checkedList, object.seq]);
																			} else {
																				var temp = [...checkedList];
																				checkedList.forEach((item, index) => {
																					if (item === object.seq) temp.splice(index, 1);
																				});
																				setCheckedList([...temp]);
																			}
																		}}
																		onClick={(e) => {
																			e.stopPropagation();
																		}}
																	/>
																)}
															</TableCell>
														) : null}
														{rowNum ? (
															<TableCell
																key={'table_row_index_' + index}
																width={'40px'}
																sx={{
																	p: print ? '1px' : '3px',
																	borderBottom: '1px solid ' + (print ? '#000' : '#D5D5D5'),
																	border: border ? '0.01px solid ' + (print ? '#3c3c3c' : '#D5D5D5') : '',
																}}
															>
																<Typography variant="caption">{index + 1}</Typography>
															</TableCell>
														) : null}
														{columns.map((column: SimpleTableColumnType, index) => {
															if (column.hide === true) {
																return null;
															}
															if (renderCell) {
																if (
																	Object.keys(renderCell).find((key) => {
																		return key === column.key;
																	})
																) {
																	return (
																		<TableCell
																			key={'table_row_' + index + column.key}
																			sx={{
																				minHeight: '32px',
																				borderRight: '1px solid #D5D5D5',
																				borderBottom: additionalRow
																					? '0px solid '
																					: '1px solid ' + (print ? '#000' : '#D5D5D5'),
																				p: print ? '1px' : '3px',
																				border: border ? '0.01px solid ' + (print ? '#3c3c3c' : '#D5D5D5') : '',
																			}}
																			colSpan={index >= columns.length - 1 ? 2 : 1}
																		>
																			{renderCell[column.key](object)}
																		</TableCell>
																	);
																} else {
																	return (
																		<TableCell
																			key={'table_row_' + index + column.key}
																			sx={{
																				minHeight: '32px',
																				borderRight: '1px solid #D5D5D5',
																				borderBottom: additionalRow
																					? '0px solid '
																					: '1px solid ' + (print ? '#000' : '#D5D5D5'),
																				p: print ? '1px' : '3px',
																				border: border ? '0.01px solid ' + (print ? '#3c3c3c' : '#D5D5D5') : '',
																			}}
																			colSpan={index >= columns.length - 1 ? 2 : 1}
																		>
																			<Typography>{object[column.key]}</Typography>
																		</TableCell>
																	);
																}
															} else {
																if (typeof object[column.key] === 'object') {
																	return (
																		<TableCell
																			key={'table_row_' + index + column.key}
																			sx={{
																				minHeight: '32px',
																				borderRight: '1px solid #D5D5D5',
																				borderBottom: additionalRow
																					? '0px solid '
																					: '1px solid ' + (print ? '#000' : '#D5D5D5'),
																				p: print ? '1px' : '3px',
																				border: border ? '0.01px solid ' + (print ? '#3c3c3c' : '#D5D5D5') : '',
																			}}
																			colSpan={index >= columns.length - 1 ? 2 : 1}
																		>
																			<Typography>{'-'}</Typography>
																		</TableCell>
																	);
																} else {
																	return (
																		<TableCell
																			key={'table_row_' + index + column.key}
																			sx={{
																				minHeight: '32px',
																				borderRight: '1px solid #D5D5D5',
																				borderBottom: additionalRow
																					? '0px solid '
																					: '1px solid ' + (print ? '#000' : '#D5D5D5'),
																				p: print ? '1px' : '3px',
																				border: border ? '0.01px solid ' + (print ? '#3c3c3c' : '#D5D5D5') : '',
																			}}
																			colSpan={index >= columns.length - 1 ? 2 : 1}
																		>
																			<Typography>{object[column.key]}</Typography>
																		</TableCell>
																	);
																}
															}
														})}
													</StyledTableRow>
													{additionalRow ? (
														additionalRow(object, index) ? (
															<StyledTableRow
																key={'table_row_additional' + index}
																onClick={(e) => {
																	if (onRowClick) {
																		onRowClick(object);
																	} else {
																		e.stopPropagation();
																	}
																}}
																sx={{
																	backgroundColor: index % 2 === 0 ? '#EBF2F4' : '#FFF',
																}}
															>
																{checkDelete ? <TableCell></TableCell> : null}
																<TableCell
																	colSpan={columns.length + 1}
																	key={'table_cell_additional' + index}
																	sx={{
																		borderRight: '1px solid #D5D5D5',
																		borderBottom: '1px solid ' + (print ? '#000' : '#D5D5D5'),
																		p: print ? '1px' : '3px',
																		border: border ? '0.01px solid ' + (print ? '#3c3c3c' : '#D5D5D5') : '',
																	}}
																>
																	{additionalRow(object, index)}
																</TableCell>
															</StyledTableRow>
														) : null
													) : null}
												</>
											);
										}
									})
								) : (
									<TableRow>
										<TableCell colSpan={columns.length} align="center">
											<Grid container alignItems={'center'} justifyContent={'center'}>
												<Grid item>
													<Typography variant="h2" justifyContent={'center'} color={'#898989'}>
														데이터 없음
													</Typography>
												</Grid>
												<Grid item>
													<FilterListOffOutlined color={'disabled'} />
												</Grid>
											</Grid>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					{!hidePagination && (
						<TablePagination
							labelDisplayedRows={({ from, to, count }) =>
								page + 1 + ' 페이지' + ' / ' + Math.ceil(count / pageRows)
							}
							labelRowsPerPage="표시 개수:"
							count={totalRows ? totalRows : datasource.length}
							component={'div'}
							rowsPerPage={pageRows}
							rowsPerPageOptions={rowsPage ? rowsPage : []}
							onRowsPerPageChange={(e) => {
								if (setRowsPage) setRowsPage(Number(e.target.value));
							}}
							page={page}
							sx={{ borderRadius: 10, '.MuiToolbar-root': { borderRadius: '20px' } }}
							onPageChange={(e, newPage) => {
								setPage(newPage);
								if (getPage) {
									Cookies.set('page', String(newPage));
									getPage(newPage);
								}
							}}
						/>
					)}
				</Box>
			</>
		);
	},
);
