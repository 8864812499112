import { BaseType } from './baseType';
import { CompanyType } from './company';
import { PeopleType } from './people';

export interface TransactionType extends BaseType, PostTransactionType {
	id: string;
}

export interface PostTransactionType {
	company: string;
	site: string;
	receiverName: string;
	receiverTel: string;
	bf: number;
	sf: number;
	mf: number;
	glass: number;
	note: string;
	outDate: Date;
}

export class TransactionType {
	constructor(entity?: Partial<any>) {
		this.id = entity?.id || 0;
		this.company = entity?.companyName || '';
		this.site = entity?.shippingAddr || '';
		this.receiverName = entity?.receiverName || '';
		this.receiverTel = entity?.receiverCellNum || '';
		this.bf = entity?.bf || 0;
		this.sf = entity?.sf || 0;
		this.mf = entity?.mf || 0;
		this.glass = entity?.gl || 0;
		this.note = entity?.note || '';
		this.outDate = entity?.shippingDate || new Date();
	}
	static toShippingOrderEntity(entity: TransactionType): any {
		return {
			id: entity.id,
			companyName: entity.company,
			shippingAddr: entity.site,
			receiverName: entity.receiverName,
			receiverCellNum: entity.receiverTel,
			bf: entity.bf,
			sf: entity.sf,
			mf: entity.mf,
			gl: entity.glass,
			note: entity.note,
			shippingDate: entity.outDate,
		};
	}
}
