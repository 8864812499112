import { Box, Button, Grid, Popover, Typography } from '@mui/material';
import { ReactNode } from 'react';

type ConfirmBoxProps = {
	message: string | ReactNode;
	element: null | (EventTarget & HTMLButtonElement);
	setElement: React.Dispatch<React.SetStateAction<(EventTarget & HTMLButtonElement) | null>>;
	onOk: () => void;
	onCancel?: () => void;
};
export const ConfirmBox = (props: ConfirmBoxProps) => {
	const { message, element, setElement, onOk, onCancel } = props;
	return (
		<Popover
			open={Boolean(element)}
			anchorEl={element}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
		>
			<Box sx={{ p: '20px', border: '2px solid' }}>
				<Grid container sx={{ mb: '10px' }}>
					<Grid item>
						{typeof message === 'string' ? (
							<Typography variant="h3" sx={{ whiteSpace: 'pre-wrap' }}>
								{message}
							</Typography>
						) : (
							message
						)}
					</Grid>
				</Grid>
				<Grid container justifyContent={'end'}>
					<Grid item>
						<Button
							variant="outlined"
							onClick={(e) => {
								onOk();
							}}
						>
							확인
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant="outlined"
							onClick={(e) => {
								if (onCancel) {
									onCancel();
								}
								setElement(null);
							}}
						>
							취소
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Popover>
	);
};
