import { ThemeProvider, styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import createTheme from './theme';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { useEffect, useState } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';

function App() {
	const content = useRoutes(routes);
	const [alert, setAlert] = useState<{ message: string; status: number; type: AlertColor }>({
		message: '',
		type: 'success',
		status: 200,
	});
	return (
		<ThemeProvider theme={createTheme('DEFAULT')}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<HelmetProvider>
					<Helmet titleTemplate="%s | (주)성화 - SHPT" defaultTitle="(주)성화 SHPT" />
					<Snackbar
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						open={alert.message !== ''}
						key={'toast'}
						autoHideDuration={2000}
						onClose={() => {
							setAlert({ message: '', type: 'error', status: 400 });
						}}
					>
						<Alert severity={alert.type ? alert.type : 'error'}>{alert.message}</Alert>
					</Snackbar>
					{content}
				</HelmetProvider>
			</LocalizationProvider>
		</ThemeProvider>
	);
}

export default App;
