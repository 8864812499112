import { AlertColor } from '@mui/material';

export interface BaseType {
	[key: string]: any;
}

export interface ResponseType<T> {
	content: T[];
	empty: boolean;
	first: boolean;
	last: boolean;
	number: number;
	numberOfElements: number;
	pageable: string;
	size: number;
	sort: {
		empty: boolean;
		sorted: boolean;
		unsorted: boolean;
	};
	totalElements: number;
	totalPages: number;
}

export interface ListRequestType<T> {
	page: number;
	size: number;
	sort?: string;
	contents?: T;
}

export const InitialEnum = {
	pallet: 'DI',
	transaction: 'OR',
	vehicle: 'VH',
};

export interface AlertType {
	message: string;
	type: AlertColor;
}
