import { SimpleTable, SimpleTableColumnType } from '@/components/Table';
import { BaseType, InitialEnum } from '@/types/baseType';
import {
	AssetType,
	PalletAdmissionType,
	PalletType,
	PostAssetType,
	PostPalletType,
} from '@/types/pallet';
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tab,
	TextField,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import pallet, { assetActions } from '@/reducers/assetReducer';
import { RootState } from '@/store';
import { ConfirmBox } from '@/components/ConfirmBox';
import { AddCircleOutline, LocalPrintshopOutlined, RefreshOutlined } from '@mui/icons-material';
import { sendPalletPrintZpl } from '@/components/ZebraZPLPrint';
import Cookies from 'js-cookie';
import { baseActions } from '@/reducers/baseReducer';
import { HistoryDataType } from '@/types/history';

const PalletColums: SimpleTableColumnType[] = [
	{ label: '', key: 'check', width: '40px', align: 'left' },
	{
		label: '시간',
		key: 'date',
		width: '10.98%',
		align: 'left',
		sort: (row: AssetType) => row.date,
	},
	{
		label: '상태',
		key: 'status',
		width: '6.02%',
		align: 'left',
		sort: (row: AssetType) => row.status,
	},
	{ label: '순번', key: 'name', width: '6.20%', align: 'left', sort: (row: AssetType) => row.name },
	{ label: '타입', key: 'type', width: '6.20%', align: 'left', sort: (row: AssetType) => row.type },
	{ label: '거래처', key: 'company', width: '10.73%', align: 'left' },
	{ label: '현장주소', key: 'site', width: '17.81%', align: 'left' },
	{ label: '연락처', key: 'receiverTel', width: '7.75%', align: 'left' },
	{ label: '비고', key: 'note', width: '15.75%', align: 'left' },
	{ label: '차량', key: 'delivery', width: '6.75%', align: 'left' },
	{ label: '연락처', key: 'deliveryTel', width: '7.75%', align: 'left' },
];

const PalletTypes = ['유리', '1t', '2t', '2.5t', 'HALF'];

type PalletTypeDialogProps = {
	dialogOpen: boolean;
	setDialogOpen: React.Dispatch<React.SetStateAction<(EventTarget & HTMLButtonElement) | boolean>>;
	isModify: boolean;
	clickedRow: AssetType | null;
	setClickedRow: React.Dispatch<
		React.SetStateAction<(EventTarget & HTMLButtonElement) | AssetType | null>
	>;
};
const PalletTypeDialog = (props: PalletTypeDialogProps) => {
	const dispatch = useDispatch();
	const { dialogOpen, setDialogOpen, isModify, clickedRow, setClickedRow } = props;

	const [palletType, setPalletType] = useState<string>('유리');
	const [assetName, setAssetName] = useState<string>('');
	const [assetStatus, setAssetStatus] = useState<string>('');
	const [confirmAnchorEl, setConfirmAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(
		null,
	);

	const { delete_success, countContents } = useSelector((state: RootState) => state.asset);

	useEffect(() => {
		if (clickedRow) {
			setAssetName(clickedRow.name);
			setPalletType(clickedRow.type);
			setAssetStatus(clickedRow.status);
		}
	}, [clickedRow]);

	useEffect(() => {
		if (delete_success) {
			dispatch(assetActions.getPalletAdmissionList({ page: 0, size: countContents }));
		}
	}, [delete_success]);

	const initData = () => {
		setPalletType('유리');
		setAssetName('');
		setAssetStatus('IN');
	};

	return (
		<Dialog
			open={dialogOpen}
			aria-labelledby="form-dialog-title"
			onClose={(e, reason) => {
				if (reason === 'escapeKeyDown') {
					setDialogOpen(false);
				}
			}}
			PaperProps={{
				style: {
					width: '550px',
				},
			}}
		>
			<DialogContent>
				<Box sx={{ p: '10px' }}>
					<ConfirmBox
						element={confirmAnchorEl}
						setElement={setConfirmAnchorEl}
						onOk={() => {
							if (clickedRow) {
								dispatch(assetActions.deleteAsset(clickedRow?.id));
								setConfirmAnchorEl(null);
								setDialogOpen(false);
							}
						}}
						onCancel={() => {
							setConfirmAnchorEl(null);
						}}
						message="선택 항목을 삭제하시겠습니까?"
					/>
					<Grid container>
						<Grid item>
							<Typography variant="h1">{'팔레트 ' + (isModify ? '수정' : '등록')}</Typography>
						</Grid>
					</Grid>
					<Grid container alignItems={'center'} sx={{ mt: '25px' }} spacing={2}>
						<Grid item xs={4}>
							<Typography variant="body1">순번 또는 명칭</Typography>
						</Grid>
						<Grid item>
							<TextField
								sx={{ maxWidth: '200px' }}
								onChange={(e) => {
									setAssetName(e.target.value);
								}}
								defaultValue={isModify ? clickedRow?.name : ''}
							></TextField>
						</Grid>
					</Grid>
					<Grid container alignItems={'center'} sx={{ mt: '25px' }}>
						<Grid item xs={4}>
							<Typography variant="body1">타입</Typography>
						</Grid>
						<Grid item>
							<Select
								sx={{ minWidth: '200px' }}
								defaultValue={isModify ? clickedRow?.type : PalletTypes.length > 0 ? PalletTypes[0] : ''}
								onChange={(e: SelectChangeEvent) => {
									if (PalletTypes.length > 0) {
										setPalletType(e.target.value);
									}
								}}
							>
								{PalletTypes.map((type) => (
									<MenuItem value={type}>{type}</MenuItem>
								))}
							</Select>
						</Grid>
					</Grid>
					<Grid container alignItems={'center'} sx={{ mt: '25px' }}>
						<Grid item xs={4}>
							<Typography variant="body1">상태</Typography>
						</Grid>
						<Grid item>
							<Select
								sx={{ minWidth: '200px' }}
								defaultValue={isModify ? clickedRow?.status : 'IN'}
								onChange={(e: SelectChangeEvent) => {
									if (PalletTypes.length > 0) {
										setAssetStatus(e.target.value);
									}
								}}
							>
								<MenuItem value={'IN'}>{'입고'}</MenuItem>
								<MenuItem value={'OUT'}>{'출고'}</MenuItem>
							</Select>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions>
				{clickedRow ? (
					<Button
						color="error"
						onClick={(e) => {
							setConfirmAnchorEl(e.currentTarget);
						}}
					>
						삭제
					</Button>
				) : null}
				<Button
					onClick={(e) => {
						setDialogOpen(false);
						if (isModify) {
							if (clickedRow) {
								dispatch(
									assetActions.postPallet({
										...clickedRow,
										name: assetName,
										type: palletType,
										status: assetStatus,
									} as AssetType),
								);
							}
						} else {
							dispatch(
								assetActions.postPallet({
									name: assetName,
									status: 'IN',
									type: palletType,
									initial: 'DI',
									note: '',
									pallets: [
										{
											name: '',
										},
									],
								} as PostAssetType),
							);
						}
						initData();
					}}
				>
					저장
				</Button>
				<Button
					onClick={(e) => {
						setDialogOpen(false);
						initData();
					}}
				>
					취소
				</Button>
			</DialogActions>
		</Dialog>
	);
};

type PalletPrintDialogProps = {
	dialogOpen: boolean;
	setDialogOpen: React.Dispatch<React.SetStateAction<(EventTarget & HTMLButtonElement) | boolean>>;
	printList: AssetType[];
	setCheckedPallets: React.Dispatch<
		React.SetStateAction<(EventTarget & HTMLButtonElement) | AssetType[]>
	>;
};
const PalletPrintDialog = (props: PalletPrintDialogProps) => {
	const dispatch = useDispatch();
	const { dialogOpen, setDialogOpen, printList, setCheckedPallets } = props;

	const [printQuantity, setPrintQuantity] = useState<number>(1);

	return (
		<Dialog
			open={dialogOpen}
			aria-labelledby="form-dialog-title"
			onClose={(e, reason) => {
				if (reason === 'escapeKeyDown') {
					setDialogOpen(false);
				}
			}}
			PaperProps={{
				style: {
					width: '550px',
				},
			}}
		>
			<DialogContent>
				<Box sx={{ p: '10px' }}>
					<Grid container>
						<Grid item>
							<Typography variant="h1">팔레트 출력</Typography>
						</Grid>
					</Grid>
					<Grid container alignItems={'center'} sx={{ mt: '25px' }}>
						<Grid item>
							<Typography variant="body1">선택된 팔레트 태그를 출력하시겠습니까?</Typography>
						</Grid>
					</Grid>
					<Grid container alignItems={'center'} sx={{ mt: '25px' }} spacing={2}>
						<Grid item xs={4}>
							<Typography variant="body1">출력 수량</Typography>
						</Grid>
						<Grid item>
							<TextField
								type="number"
								sx={{ maxWidth: '200px' }}
								onChange={(e) => {
									setPrintQuantity(Number(e.target.value));
								}}
							></TextField>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={(e) => {
						sendPalletPrintZpl(printList, printQuantity);
						dispatch(baseActions.setAlert({ message: '프린트 전송 완료', type: 'success' }));
						setDialogOpen(false);
						setCheckedPallets([]);
					}}
				>
					확인
				</Button>
				<Button
					onClick={(e) => {
						setDialogOpen(false);
					}}
				>
					취소
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const PalletBoard = () => {
	const dispatch = useDispatch();
	const [createModal, setCreateModal] = useState<boolean>(false);
	const [printModal, setPrintModal] = useState<boolean>(false);
	const [confirmAnchorEl, setConfirmAnchorEl] = useState<null | (EventTarget & HTMLButtonElement)>(
		null,
	);
	const [palletList, setPalletList] = useState<PalletAdmissionType[]>([]);
	const [checkedPallets, setCheckedPallets] = useState<AssetType[]>([]);
	const [clickedRow, setClickedRow] = useState<AssetType | null>(null);
	const [page, setPage] = useState<number>(0);
	const [rowsPage, setRowsPage] = useState<number>(20);

	const { pallet_list, created_asset, countContents } = useSelector(
		(state: RootState) => state.asset,
	);

	useEffect(() => {
		const cookie_page_str = Cookies.get('page');
		if (cookie_page_str) {
			const cookie_page = Number(cookie_page_str);
			if (cookie_page === page) {
				dispatch(assetActions.getPalletAdmissionList({ page: page, size: rowsPage }));
			}
		}
	}, [page, rowsPage]);

	useEffect(() => {
		if (created_asset) {
			const cookie_page_str = Cookies.get('page');
			if (cookie_page_str) {
				const cookie_page = Number(cookie_page_str);
				if (cookie_page === page) {
					dispatch(assetActions.getPalletAdmissionList({ page: page, size: rowsPage }));
				}
			} else {
				dispatch(assetActions.getPalletAdmissionList({ page: 0, size: rowsPage }));
			}
			setClickedRow(null);
		}
	}, [created_asset]);

	useEffect(() => {
		if (createModal == false) {
			setClickedRow(null);
		}
	}, [createModal]);

	useEffect(() => {
		if (pallet_list.length < 1) {
		} else {
			if (pallet_list.length === countContents) {
				setPalletList([
					...pallet_list.map((entity: any) => {
						return new PalletAdmissionType(entity);
					}),
				]);
			} else {
				setRowsPage(countContents);
				dispatch(assetActions.getPalletAdmissionList({ page: 0, size: countContents }));
			}
		}
	}, [pallet_list]);

	const parseAdmission = (asset: PalletAdmissionType) => {
		if (asset.latestAdmission) {
			const admission = asset.latestAdmission;
			let tempData: HistoryDataType = {} as HistoryDataType;
			tempData.id = admission.id;
			tempData.date = admission.date;
			tempData.status = admission.status;
			if (admission.assets.length > 0) {
				admission.assets.forEach((asset: AssetType) => {
					Object.entries(InitialEnum).forEach((initial) => {
						if (asset.initial == initial[1]) {
							if (initial[1] == 'DI') {
								tempData[initial[0]] = asset;
							} else if (initial[1] == 'OR') {
								if (tempData.transaction) {
									tempData.transaction.push(asset[initial[0] + 's'][0]);
								} else {
									tempData[initial[0]] = [asset[initial[0] + 's'][0]];
								}
							} else {
								tempData[initial[0]] = asset[initial[0] + 's'][0];
							}
						}
					});
					if (tempData.assets) {
						tempData.assets.push(asset);
					} else {
						tempData.assets = [asset];
					}
				});
			}
			return tempData;
		}
		return null;
	};

	const PalletCount = () => {
		return PalletTypes.length > 0 ? (
			PalletTypes.map((type: string) => {
				let count = pallet_list
					.filter((pallet: PalletAdmissionType) => pallet.asset.type == type)
					.filter((pallet: PalletAdmissionType) => pallet.asset.status == 'IN').length;
				return (
					<Grid item xs>
						<Typography variant="h3">{type + ' : ' + count}</Typography>
					</Grid>
				);
			})
		) : (
			<div />
		);
	};

	const checkPallet = (row: AssetType) => {
		if (checkedPallets.filter((p) => p.id == row.id).length > 0) {
			return true;
		} else {
			return false;
		}
	};
	return (
		<Box>
			<PalletPrintDialog
				dialogOpen={printModal}
				setDialogOpen={setPrintModal}
				printList={checkedPallets}
				setCheckedPallets={setCheckedPallets}
			/>
			<PalletTypeDialog
				dialogOpen={createModal}
				setDialogOpen={setCreateModal}
				isModify={clickedRow != null}
				clickedRow={clickedRow}
				setClickedRow={setClickedRow}
			/>
			<Grid container justifyContent={'space-between'}>
				<Grid item xs></Grid>
				<Grid item xs={6}>
					<Grid container justifyContent={'center'} alignItems={'center'}>
						<PalletCount />
						<Grid item>
							<Button
								onClick={() => {
									dispatch(assetActions.getPalletAdmissionList({ page: 0, size: rowsPage }));
								}}
							>
								<RefreshOutlined /> 새로고침
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs></Grid>
				<Grid item sx={{ mr: '30px' }}>
					<Button
						onClick={() => {
							setCreateModal(true);
						}}
					>
						<AddCircleOutline /> 등록
					</Button>
				</Grid>
				<Grid item>
					<Button
						onClick={(e) => {
							setConfirmAnchorEl(e.currentTarget);
							setPrintModal(true);
						}}
					>
						<LocalPrintshopOutlined /> 출력
					</Button>
				</Grid>
			</Grid>
			<Grid container sx={{ mt: '30px', border: '1px solid #3c3c3c' }}>
				<Grid item xs>
					<SimpleTable
						columns={[...PalletColums] as SimpleTableColumnType[]}
						datasource={palletList}
						pageRows={rowsPage}
						totalRows={countContents}
						getPage={setPage}
						rowsPage={[20, 40, 80]}
						setRowsPage={setRowsPage}
						onRowClick={(row: PalletAdmissionType, e: any) => {
							if (e?.target.type !== 'checkbox') {
								setClickedRow(row.asset);
								setCreateModal(true);
							}
						}}
						renderCell={{
							check: (row: PalletAdmissionType) => {
								return (
									<Checkbox
										checked={checkPallet(row.asset)}
										onChange={(e) => {
											if (e.target.checked) {
												let temp = [...checkedPallets];
												temp.push(row.asset);
												setCheckedPallets(temp);
											} else {
												let temp = checkedPallets.filter((pallet) => pallet.id !== row.asset.id);
												setCheckedPallets(temp);
											}
											e.stopPropagation();
										}}
										size="small"
									/>
								);
							},
							date: (row: PalletAdmissionType) => {
								if (row.latestAdmission.id === 0) {
									return '-';
								} else {
									return dayjs(row.latestAdmission.date).format('YY-MM-DD HH:mm:ss');
								}
							},
							status: (row: PalletAdmissionType) => {
								const isIn = row.asset.status == 'IN';
								return (
									<Typography sx={{ color: isIn ? '#062DDC' : '#DB1E09', fontWeight: '600' }}>
										{isIn ? '입고' : '출고'}
									</Typography>
								);
							},
							name: (row: PalletAdmissionType) => {
								return <Typography>{row.asset.name}</Typography>;
							},
							type: (row: PalletAdmissionType) => {
								return <Typography>{row.asset.type}</Typography>;
							},
							company: (row: PalletAdmissionType) => {
								const admission = parseAdmission(row);
								if (admission) {
									if (admission.transaction) {
										return (
											<Box sx={{ whiteSpace: 'pre-line' }}>
												{admission.transaction?.map((tr) => tr.company).join('\r\n')}
											</Box>
										);
									} else {
										return '-';
									}
								}
							},
							site: (row: PalletAdmissionType) => {
								const admission = parseAdmission(row);
								if (admission) {
									if (admission.transaction) {
										return (
											<Box sx={{ whiteSpace: 'pre-line' }}>
												{admission.transaction?.map((tr) => tr.site).join('\r\n')}
											</Box>
										);
									} else {
										return '-';
									}
								}
							},
							receiver: (row: PalletAdmissionType) => {
								const admission = parseAdmission(row);
								if (admission) {
									if (admission.transaction) {
										return (
											<Box sx={{ whiteSpace: 'pre-line' }}>
												{admission.transaction?.map((tr) => tr.receiver).join('\r\n')}
											</Box>
										);
									} else {
										return '-';
									}
								}
							},
							receiverTel: (row: PalletAdmissionType) => {
								const admission = parseAdmission(row);
								if (admission) {
									if (admission.transaction) {
										return (
											<Box sx={{ whiteSpace: 'pre-line' }}>
												{admission.transaction?.map((tr) => tr.receiverTel).join('\r\n')}
											</Box>
										);
									} else {
										return '-';
									}
								}
							},
							note: (row: PalletAdmissionType) => {
								const admission = parseAdmission(row);
								if (admission) {
									if (admission.transaction) {
										return (
											<Box sx={{ whiteSpace: 'pre-line' }}>
												{admission.transaction?.map((tr) => tr.note).join('\r\n')}
											</Box>
										);
									} else {
										return '-';
									}
								}
							},
							delivery: (row: PalletAdmissionType) => {
								const admission = parseAdmission(row);
								if (admission) {
									if (admission.vehicles) {
										return admission.vehicles[0]?.car_number;
									} else {
										return '-';
									}
								}
							},
							deliveryTel: (row: PalletAdmissionType) => {
								const admission = parseAdmission(row);
								if (admission) {
									if (admission.vehicles) {
										return admission.vehicles[0]?.tel;
									} else {
										return '-';
									}
								}
							},
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PalletBoard;
