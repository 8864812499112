import { CompanyType } from './company';

export interface PeopleType extends PostPeopleType {
	id: string;
}
export interface PostPeopleType {
	name: string;
	tel: string;
	car_number: string;
	company: string;
}

export class PeopleType {
	constructor(entity?: Partial<any>) {
		this.id = entity?.id || -1;
		this.name = entity?.driverName || '';
		this.tel = entity?.driverCellNum || '';
		this.car_number = entity?.number || '';
		this.company = entity?.companyName || '';
	}
	static toVehicleEntity(entity: PeopleType): any {
		return {
			id: entity.id,
			number: entity.car_number,
			driverName: entity.name,
			driverCellNum: entity.tel,
			companyName: entity.company,
		};
	}
}
